import React from 'react';
import SimpleChart from '~/components/Brand/SimpleChart';
import getIcon from '~/components/Icon';
import { Container, TopPart, LabelBlock, Label, Value, Unit } from './styles';

function ChartBlock({ value, data, name, dataPointPath, isOffline, t, unit, timezone, round }) {
  const Icon = getIcon(dataPointPath);
  const title = t(dataPointPath, name);
  return (
    <Container offline={isOffline}>
      <TopPart>
        <LabelBlock>
          {Icon && <Icon />}
          <Label>{title}</Label>
        </LabelBlock>
        <Value>
          {value}
          <Unit>{unit}</Unit>
        </Value>
      </TopPart>
      <SimpleChart offline={isOffline} data={data} path={dataPointPath} timezone={timezone} title={title} round={round} />
    </Container>
  );
}

export default ChartBlock;
