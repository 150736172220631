import { space, radii } from './space';
import { fonts, fontSizes } from './typography';
import shadows from './shadows';
import breakpoints from './breakpoints';

export default {
  fonts,
  fontSizes,
  space,
  radii,
  shadows,
  breakpoints
};
