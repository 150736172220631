import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import GlobalStyles from '~/themes/base/global';
import { getTheme } from '~/utils/browser';
import configureStore from '~/store';
import history from '~/store/history';
import { darkTheme, lightTheme } from '~/themes';
import OnlineStatusOverlay from '~/components/OnlineStatusOverlay';
import Routes from './routes';

function App() {
  const store = configureStore();
  const theme = getTheme() === 'light' ? lightTheme : darkTheme;

  return (
    <Sentry.ErrorBoundary fallback="An error has occurred">
      <Provider store={store}>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <OnlineStatusOverlay>
              <Router navigator={history} location={history.location}>
                <Routes />
              </Router>
            </OnlineStatusOverlay>
          </ThemeProvider>
        </HelmetProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
