import baseColors from '~/themes/base/colors';

export default {
  mainBackground: baseColors.darkGrey20,
  mainTitle: baseColors.blueWhite50,
  mainSubtitle: baseColors.grey10,

  widgetTitle: baseColors.blueWhite20,
  widgetValue: baseColors.blueWhite20,
  widgetLabel: baseColors.blueWhite20,
  widgetBackground: baseColors.darkGrey10,
  widgetBorder: baseColors.grey20,
  widgetIcon: baseColors.grey20,

  loadingLabel: baseColors.grey20,

  gaugeBackground: baseColors.transparent,
  gaugeDefaultLine: baseColors.darkGrey20,

  lineChartLineColor: baseColors.brightBlue,
  lineChartAxisLines: baseColors.darkGrey20,
  lineChartAxisLabels: baseColors.blueWhite50,
  lineChartAxisValues: baseColors.darkGrey20,
  lineChartGridColor: baseColors.darkGrey20,

  statsValue: baseColors.brightBlue,
  statsLabel: baseColors.blueWhite50
};
