/* eslint-disable import/no-import-module-exports */
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import createSentryMiddleware from 'redux-sentry-middleware';
import history from '~/store/history';
import rootSaga from '~/store/sagas';
import { flatJson, prepareStateForSentry, prepareActionForSentry } from '~/utils/helpers';
import createRootReducer from '~/store/reducers';

const rootReducer = createRootReducer();

export default function configureAppStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware({
    context: {
      history
    }
  });
  const sentryMiddleware = createSentryMiddleware(Sentry, {
    stateTransformer(state) {
      return flatJson(prepareStateForSentry(state));
    },
    actionTransformer(action) {
      return flatJson(prepareActionForSentry(action));
    }
  });
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      sentryMiddleware,
      sagaMiddleware,
      ...getDefaultMiddleware({ immutableCheck: true, serializableCheck: false })
    ],
    preloadedState
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
