import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { getDataPointByPath } from '~/store/selectors/dataPoints';
import { getStatisticsQualityNumbers } from '~/store/selectors/profiles';
import { PARAMETER_PATHS } from '~/utils/constants';
import ExposureTime from '~/components/Brand/ExposureTime';

const mapStateToProps = (state) => {
  const { device_id: deviceId, _id: dataPointId } = getDataPointByPath(state, PARAMETER_PATHS.AQ);

  const qualityNumbers = dataPointId ? getStatisticsQualityNumbers(state, dataPointId, deviceId) : [];
  return {
    qualityNumbers
  };
};

export default connect(mapStateToProps)(withTheme(ExposureTime));
