import { call, put, fork, takeEvery, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { normalizeDataPointsResp } from '~/utils/helpers';
import * as ActionTypes from '~/store/reducers/dataPoints';
import * as schema from '~/store/schema';
import requestApi from '~/utils/axios';
import { getAuthIdToken } from '~/store/selectors/auth';

export function* fetchDataPointsSaga({ payload }) {
  try {
    const { paths, from } = payload;
    const token = yield select(getAuthIdToken);
    const { data } = yield call(
      requestApi,
      'post',
      'search/data_points',
      {
        path: paths,
        from
      },
      { token }
    );
    const response = normalize(normalizeDataPointsResp(data), schema.dataPointsSchema);
    yield put(ActionTypes.fetchDataPointsSuccess(response));
  } catch (e) {
    yield put(ActionTypes.fetchDataPointsFailure({ error: e }));
  }
}

function* watchFetchDataPointsData() {
  yield takeEvery(ActionTypes.fetchDataPointsRequest, fetchDataPointsSaga);
}

export default function* app() {
  yield fork(watchFetchDataPointsData);
}
