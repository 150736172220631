import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body{
    margin: 0 !important;
    padding: 0 !important;
    font-family: ${({ theme }) => theme.fonts.sansSerif};
    background-color: ${({ theme }) => theme.colors.mainBackground};
  }
`;
