import styled from 'styled-components';
import { Column, Text } from '~/components/Basic';

export const Container = styled(Column)`
  font-size: 20px;
  color: white;
  position: absolute;
  top: 3rem;
  left: 3rem;

  @media (max-width: 1024px) {
    font-size: 1.15em;
    top: 1.5rem;
    left: 1.5rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const Name = styled(Text).attrs({
  as: 'h2'
})`
  font-size: 1.5em;
  font-weight: bold;
`;

export const DeviceId = styled(Text).attrs({
  as: 'p'
})`
  color: #a9e0ff;
  font-size: 1em;
  font-weight: normal;
`;
