import { fork } from 'redux-saga/effects';
import app from './app';
import appVersion from './appVersion';
import stream from './stream';
import nodes from './nodes';
import dataPoints from './dataPoints';
import rotating from './rotating';
import statuses from './statuses';

export default function* root() {
  yield fork(appVersion);
  yield fork(app);
  yield fork(dataPoints);
  yield fork(stream);
  yield fork(nodes);
  yield fork(rotating);
  yield fork(statuses);
}
