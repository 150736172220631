import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { getLastMeasurementTs } from '~/store/selectors/measurements';
import { getNodesIdFirst, getNodeDataAggregation } from '~/store/selectors/nodes';
// eslint-disable-next-line import/no-unresolved
import RefreshIcon from '~/assets/icons/icon-refresh.svg?react';
import { Container, Tooltip, IconContainer } from './styles';

moment.locale('en', {
  relativeTime: {
    future: '%s',
    past: '%s',
    s: '<1min',
    ss: '<1min',
    m: '1min',
    mm: '%dmin',
    h: '1hr',
    hh: '%dhr',
    d: '1day',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1yr',
    yy: '%dyr'
  }
});

const convertMinutestToTimeAgo = (minutes) => {
  if (minutes <= 15) {
    return '15mins';
  }
  if (minutes <= 30) {
    return '30mins';
  }
  if (minutes <= 45) {
    return '45mins';
  }
  if (minutes <= 60) {
    return '1hr';
  }
  if (minutes <= 120) {
    return '2hrs';
  }
  if (minutes <= 240) {
    return '4hrs';
  }
  if (minutes > 240) {
    return '6hrs';
  }

  return '';
};

function TimeAgo({ aggregation, lastTs }) {
  const [lastUpdate, setLastUpdate] = useState(aggregation ? convertMinutestToTimeAgo(aggregation) : moment(lastTs).fromNow());
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    let interval = null;
    if (!aggregation) {
      setLastUpdate(moment(lastTs).fromNow());
      interval = setInterval(() => {
        setLastUpdate(moment(lastTs).fromNow());
      }, 30000);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [lastTs]);

  return (
    <Container onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} onClick={() => setShowTooltip(!showTooltip)}>
      <IconContainer>
        <RefreshIcon />
      </IconContainer>
      {lastUpdate}
      <Tooltip open={showTooltip}>{aggregation ? 'Average Time Reported' : 'Last Time Reported'}</Tooltip>
    </Container>
  );
}

const mapStateToProps = (state) => {
  const nodeId = getNodesIdFirst(state);
  const lastTs = getLastMeasurementTs(state);
  const aggregation = getNodeDataAggregation(state, nodeId);

  return {
    lastTs,
    aggregation
  };
};

export default connect(mapStateToProps)(TimeAgo);
