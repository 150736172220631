import React from 'react';
import PropTypes from 'prop-types';
import { Container, MessageContainer, Title, Body } from '~/components/Overlay/styles';

function Overlay(props) {
  const { icon } = props;
  const { icon: Icon, title, message } = props;
  return (
    <Container>
      <MessageContainer>
        {icon && <Icon />}
        <Title>{title}</Title>
        <Body>{message}</Body>
      </MessageContainer>
    </Container>
  );
}

Overlay.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default Overlay;
