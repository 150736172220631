import { put, fork, take, takeEvery, all, call, select, cancelled } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import requestApi from '~/utils/axios';
import { getAppIsNetworkOnline } from '~/store/selectors/app';
import { setApiStatus, checkApiStatus, setNetworkStatus, checkNetworkStatus, stopRotatingParameters, retryCheckApiStatus } from '../reducers/app';
import { createInterval } from './utils';

const CHECK_API_STATUS_INTERVAL = 300000;
const RETRY_CHECK_API_STATUS_INTERVAL = 60000;

function createNavigatorOnlineEventListener() {
  return eventChannel((emitter) => {
    const updateOnlineStatus = () => {
      if (emitter) {
        emitter('');
      } else {
        emitter(END);
      }
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  });
}

function createNetworkStatusChecking() {
  return createInterval(CHECK_API_STATUS_INTERVAL);
}

function createRetryApiStatusChecking() {
  return createInterval(RETRY_CHECK_API_STATUS_INTERVAL);
}

export function* navigatorOnlineStatusSaga() {
  const chan = yield call(createNavigatorOnlineEventListener);
  try {
    while (true) {
      yield take(chan);
      if (navigator.onLine) {
        const previousStatus = yield select(getAppIsNetworkOnline);

        if (!previousStatus) {
          document.location.reload(true);
        }
      } else {
        yield put(stopRotatingParameters());
        yield put(setNetworkStatus(false));
      }
    }
  } finally {
    if (yield cancelled()) {
      chan.close();
    }
  }
}

export function* checkApiStatusSaga() {
  const chan = yield call(createNetworkStatusChecking);
  while (true) {
    try {
      yield take(chan);

      const isNetworkOnline = yield select(getAppIsNetworkOnline);

      if (isNetworkOnline) {
        yield call(requestApi, 'GET', '/status');
      }
    } catch (e) {
      yield put(setApiStatus(false));
      yield put(stopRotatingParameters());
      yield put(retryCheckApiStatus());
      chan.close();
      break;
    }
  }
}

export function* retryCheckApiStatusSaga() {
  const chan = yield call(createRetryApiStatusChecking);

  while (true) {
    yield take(chan);
    try {
      yield call(requestApi, 'GET', '/status');

      document.location.reload(true);
    } catch (e) {
      yield put(setApiStatus(false));
    } finally {
      if (yield cancelled()) {
        chan.close();
      }
    }
  }
}

export function* watchNavigatorOnlineStatus() {
  yield takeEvery(checkNetworkStatus, navigatorOnlineStatusSaga);
}

export function* watchCheckApiStatus() {
  yield takeEvery(checkApiStatus, checkApiStatusSaga);
  yield takeEvery(retryCheckApiStatus, retryCheckApiStatusSaga);
}

export default function* statuses() {
  yield all([fork(watchCheckApiStatus), fork(watchNavigatorOnlineStatus)]);
}
