import { put, fork, takeLatest, take, all, select, getContext, cancelled, call } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import uniq from 'lodash/uniq';
import moment from 'moment';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import * as ActionTypes from '~/store/reducers/app';
import { setIdToken } from '~/store/reducers/auth';
import { getNodeIsOffline, getLastMeasurementTs } from '~/store/selectors/measurements';
import { checkNewRelease } from '~/store/reducers/appVersion';
import { createRequest as streamCreateRequest } from '~/store/reducers/measurements';
import { fetchNodesRequest, fetchNodesSuccess } from '~/store/reducers/nodes';
import { fetchDataPointsRequest, fetchDataPointsSuccess } from '~/store/reducers/dataPoints';
import { getProfilePaths } from '~/store/selectors/profiles';
import { getNodeTemperatureUnits, getNodesIdFirst, getNodeVocUnits, getNodePmUnits } from '~/store/selectors/nodes';
import { ADDITIONAL_DATA_POINTS_DEFAULT_PROFILE, OFFLINE_INTERVAL } from '~/utils/constants';

export function* appStartRequestSaga({ payload }) {
  try {
    const { token } = payload;
    yield put(setIdToken(token));
    yield put(fetchNodesRequest());
    yield take(fetchNodesSuccess);
    const nodeId = yield select(getNodesIdFirst);
    const paths = yield select(getProfilePaths);
    const now = moment().utc();
    let params = ADDITIONAL_DATA_POINTS_DEFAULT_PROFILE;
    const tempUnits = yield select(getNodeTemperatureUnits, nodeId);
    const vocUnits = yield select(getNodeVocUnits, nodeId);
    const pmUnits = yield select(getNodePmUnits, nodeId);
    if (tempUnits) {
      params = [...params, tempUnits];
    }
    if (vocUnits) {
      params = [...params, vocUnits];
    }
    if (isArray(pmUnits) && !isEmpty(pmUnits)) {
      params = [...params, ...pmUnits];
    }
    yield put(
      fetchDataPointsRequest({
        paths: uniq([...paths, ...params]),
        from: now.subtract(1, 'd').valueOf()
      })
    );
    yield put(ActionTypes.startSuccess());
  } catch (e) {
    yield put(ActionTypes.startFailure({ error: e }));
  }
}

export function* watchAppRestartRequest() {
  yield takeLatest(ActionTypes.startRequest, appStartRequestSaga);
}

function createIntervalChannel() {
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      emitter(true);
    }, OFFLINE_INTERVAL / 3);
    return () => {
      clearInterval(iv);
    };
  });
}

function* offlineCheckSaga() {
  const chan = yield call(createIntervalChannel);
  try {
    while (true) {
      yield take(chan);
      const lastTs = yield select(getLastMeasurementTs);
      if (getNodeIsOffline(lastTs)) {
        const history = yield getContext('history');
        history.go(0);
      }
    }
  } finally {
    if (yield cancelled()) {
      chan.close();
    }
  }
}

export function* afterAppStartSaga() {
  try {
    yield put(checkNewRelease());
    yield put(ActionTypes.checkNetworkStatus());
    yield put(ActionTypes.checkApiStatus());
    yield put(streamCreateRequest());
    yield take(fetchDataPointsSuccess);
    yield call(offlineCheckSaga);
  } catch (e) {
    yield put(ActionTypes.startFailure({ error: e }));
  }
}

export function* watchAppStartSuccess() {
  yield takeLatest(ActionTypes.startSuccess, afterAppStartSaga);
}

export default function* app() {
  yield all([fork(watchAppRestartRequest), fork(watchAppStartSuccess)]);
}
