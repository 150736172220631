import React from 'react';
import { connect } from 'react-redux';
import { getNodesIdFirst, getNodesById } from '~/store/selectors/nodes';
import { Container, DeviceId, Name } from './styles';

function Locaiton({ name, id }) {
  return (
    <Container>
      <Name>{name}</Name>
      <DeviceId>{id}</DeviceId>
    </Container>
  );
}

const mapStateToProps = (state) => {
  const nodeId = getNodesIdFirst(state);
  const node = getNodesById(state, nodeId);

  return {
    id: node ? node.serial : '',
    name: node ? node.name : ''
  };
};

export default connect(mapStateToProps)(Locaiton);
