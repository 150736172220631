import { eventChannel, END } from 'redux-saga';

export function createInterval(interval) {
  return eventChannel((emitter) => {
    const id = setInterval(() => {
      if (emitter) {
        emitter('');
      } else {
        emitter(END);
      }
    }, interval);
    return () => {
      clearInterval(id);
    };
  });
}
