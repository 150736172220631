import styled from 'styled-components';
import { typography, color, space } from 'styled-system';

export const Text = styled.p`
  margin: 0;
  ${typography}
  ${color}
  ${space}
`;

export const DisplayText = styled(Text)`
  text-transform: uppercase;
`;
