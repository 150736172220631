export const space = {
  HALF: 5,
  BASE: 10,
  THREEBYTWO: 15,
  DOUBLE: 20,
  QUAD: 40
};

export const radii = {
  BIG: 10
};
