import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import queryString from 'query-string';
import { startRequest } from '~/store/reducers/app';
import history from '~/store/history';
import Loading from '~/components/Loading';
import { getNodesIsFetched, getIsFetchFailure, getNodesIsFetching } from '~/store/selectors/dataPoints';
import logoSrc from '~/assets/logo.png';

const withAppStart = (ComposedComponent) => {
  function Hoc(props) {
    const queryParams = queryString.parse(history.location.search);
    const dispatch = useDispatch();
    const { isFetched, isFetchFailure, isFetching } = props;
    useEffect(() => {
      if (!isFetched && !isFetching) {
        dispatch(startRequest(queryParams));
      }
    }, [dispatch, isFetched, isFetching]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isFetched || isFetchFailure) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <ComposedComponent {...props} />;
    }
    return <Loading logo={logoSrc} />;
  }

  const mapStateToProps = (state) => {
    const isFetched = getNodesIsFetched(state);
    const isFetching = getNodesIsFetching(state);
    const isFetchFailure = getIsFetchFailure(state);
    return {
      isFetched,
      isFetching,
      isFetchFailure
    };
  };

  return connect(mapStateToProps)(Hoc);
};

export default withAppStart;
