import React, { Component, createRef } from 'react';
import styled, { withTheme } from 'styled-components';
import { Column, Flex, Grid } from '~/components/Basic';
import { Text } from '~/components/Basic/typography';
import { WidgetContainer } from './styles';

const Path = styled.path`
  stroke: ${({ strokeColor }) => strokeColor || 'black'};
  stroke-width: ${({ strokeWidth }) => `${strokeWidth}px` || '15px'};
  stroke-linecap: ${({ rounded }) => (rounded ? 'round' : 'butt')};
  stroke-dashoffset: ${({ strokeOffset }) => `${strokeOffset}px` || '0'};
  stroke-dasharray: ${(props) => `${props.arcLength}px`};
  transition:
    stroke-dashoffset 0.15s ease-in-out,
    stroke-width 0.1s ease-in-out,
    stroke 0.2s ease-in-out;
`;

export const ArcContainer = styled(Column).attrs({})`
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  & > div {
    width: 100%;
    height: 100%;
    & > div {
      height: 100%;
    }
  }
`;

export const GaugeTitle = styled(Text).attrs({
  fontSize: ['widgetTitleSmall', 'widgetTitle', 'widgetTitle'],
  color: 'widgetTitle',
  fontWeight: '700',
  mb: 'HALF'
})``;

export function WidgetFull(props) {
  // Passed Values
  const { title, width, value, progress, arrowSize } = props;
  const { ranges, getRange } = props;
  const { arcWidth = 3, arcRounded = true, arcBackgroundColor = '#D7DFE5' } = props;
  const { tickColor = '#D7DFE5', tickWidth = 1, tickOffset = 20, tickLong = 10, tickShort = 5 } = props;

  // Computed Values
  const ticksRadius = Math.floor((width - tickOffset - arcWidth * 3) / 2);
  const center = { x: width / 2, y: width / 2 };
  const chartHeight = arcWidth * 2 + width / 2;
  const { color, status, icon: StatusIcon } = getRange(progress, ranges);
  const paintColor = color;

  return (
    <WidgetContainer>
      <GaugeTitle>{title}</GaugeTitle>
      <ArcContainer>
        <svg width={width} height={chartHeight} fill="none">
          <WidgetArc color={paintColor} bgColor={arcBackgroundColor} width={arcWidth} progress={progress} chartWidth={width} rounded={arcRounded} />
          <WidgetTicks center={center} tickWidth={tickWidth} radius={ticksRadius} length={tickLong} color={tickColor} steps={5} />
          <WidgetTicks
            center={center}
            tickWidth={tickWidth}
            radius={ticksRadius}
            length={tickShort}
            color={tickColor}
            steps={21}
            skip={(i) => i % 5 === 0}
          />
        </svg>
        <GaugeArrow offsetY={20} size={arrowSize} value={progress} min={0} max={100} />
      </ArcContainer>
      <GaugeValue>{value}</GaugeValue>
      <Flex alignItems="center" justifyContent="center">
        <GaugeIconBox>
          <StatusIcon color={paintColor} />
        </GaugeIconBox>
        <GaugeLabel>{status}</GaugeLabel>
      </Flex>
    </WidgetContainer>
  );
}

class SimpleWidgetArc extends Component {
  constructor() {
    super();
    this.pathRef = createRef();
    this.state = {
      arcLength: 0
    };
  }

  componentDidMount() {
    this.updateArcLength();
  }

  componentDidUpdate(prevProps, prevState) {
    const { arcLength } = this.state;
    if (prevState.arcLength === arcLength) {
      const equalWidth = this.equalPropKey('width', prevProps);
      const equalChartWidth = this.equalPropKey('chartWidth', prevProps);
      const equalProgress = this.equalPropKey('progress', prevProps);
      const equalColors = this.equalPropKey('color', prevProps);
      if (!equalWidth || !equalProgress || !equalColors || !equalChartWidth) {
        this.updateArcLength();
      }
    }
  }

  equalPropKey(key, old) {
    // eslint-disable-next-line react/destructuring-assignment
    return old[key] === this.props[key];
  }

  updateArcLength() {
    if (this.pathRef.current) {
      const length = this.pathRef.current.getTotalLength();
      this.setState({
        arcLength: Math.ceil(length)
      });
    }
  }

  render() {
    const { color, bgColor = '#D7DFE5', width, progress, rounded = true } = this.props;
    const { chartWidth = 200 } = this.props;
    const { children } = this.props;
    const { arcLength } = this.state;
    const chartHeight = width + chartWidth / 2;
    const arcHeight = chartHeight;
    const strokeOffset = ((100 - progress) / 100) * arcLength;

    const pathPoints = `
    M ${width} ${arcHeight - width}
    C ${width} ${arcHeight / 2} ${chartWidth / 4} ${width} ${chartWidth / 2} ${width}
    C ${(3 * chartWidth) / 4} ${width} ${chartWidth - width} ${arcHeight / 2} ${chartWidth - width} ${arcHeight - width}
    `;

    return (
      <svg id="arc" width={chartWidth} height={chartHeight} viewBox={`0 0 ${chartWidth} ${chartHeight}`} fill="none">
        <Path ref={this.pathRef} d={pathPoints} arcLength={arcLength} strokeColor={bgColor} strokeWidth={width} strokeOffset={0} rounded={rounded} />
        <Path d={pathPoints} arcLength={arcLength} strokeColor={color} strokeWidth={width} strokeOffset={strokeOffset} rounded={rounded} />
        {children}
      </svg>
    );
  }
}

export const WidgetArc = withTheme(SimpleWidgetArc);

const TickLine = styled.line`
  stroke: ${({ strokeColor }) => strokeColor || 'black'};
  stroke-width: ${({ strokeWidth }) => `${strokeWidth}px` || '15px'};
  stroke-linecap: ${({ rounded }) => (rounded ? 'round' : 'butt')};
  transition:
    stroke-width 0.15s ease-in-out,
    stroke 0.2s ease-in-out;
`;

export function WidgetTicks(props) {
  const { center, color = 'red', tickWidth = 2, length = 4, radius = 10, steps = 10, rounded = true, skip = null } = props;
  const ticks = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < steps; i++) {
    if (skip && skip(i, steps)) {
      // eslint-disable-next-line no-continue
      continue;
    }
    const angleStep = steps === 0 ? 180 : 180 / (steps - 1);
    const angle = 180 - angleStep * i;
    const rad = (Math.PI * angle) / 180;
    const centerX = center ? center.x : radius + tickWidth;
    const centerY = center ? center.y : radius + tickWidth;
    const cos = Math.cos(rad);
    const sin = Math.sin(rad);
    const r1 = radius;
    const r2 = radius - length;
    const x1 = (centerX + r1 * cos).toFixed(2);
    const x2 = (centerX + r2 * cos).toFixed(2);
    const y1 = (centerY - r1 * sin).toFixed(2);
    const y2 = (centerY - r2 * sin).toFixed(2);
    const coords = {
      x1,
      x2,
      y1,
      y2
    };
    ticks.push(coords);
  }

  return (
    <>
      {ticks.map((coords, i) => (
        <TickLine
          {...coords} // eslint-disable-line react/jsx-props-no-spreading
          key={i} // eslint-disable-line react/no-array-index-key
          strokeWidth={tickWidth}
          strokeColor={color}
          rounded={rounded}
        />
      ))}
    </>
  );
}

export const GaugeValue = styled(Text).attrs({
  mb: 'HALF',
  color: 'widgetValue',
  fontSize: ['widgetValueSmall', 'widgetValue', 'widgetValue']
})`
  font-size: 24px;
  font-weight: 900;
  flex: 0 0 auto;
`;

export const GaugeLabel = styled(Text).attrs({
  color: 'widgetLabel',
  fontSize: 'fineprint'
})`
  font-weight: 400;
`;

export const GaugeIconBox = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  mr: 'HALF'
})`
  width: 24px;
  height: 24px;
  opacity: 0.75;
`;

export function IconLike({ color }) {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.5" r="10" fill={color} />
      <path
        /* eslint-disable-next-line max-len */
        d="M6.76138 8.90909H5.05684C4.77441 8.90909 4.54547 9.13803 4.54547 9.42045V14.5341C4.54547 14.8165 4.77441 15.0455 5.05684 15.0455H6.76138C7.0438 15.0455 7.27275 14.8165 7.27275 14.5341V9.42045C7.27275 9.13803 7.0438 8.90909 6.76138 8.90909ZM5.90911 14.1932C5.62669 14.1932 5.39774 13.9642 5.39774 13.6818C5.39774 13.3994 5.62669 13.1705 5.90911 13.1705C6.19153 13.1705 6.42047 13.3994 6.42047 13.6818C6.42047 13.9642 6.19153 14.1932 5.90911 14.1932ZM12.7273 5.87185C12.7273 6.7756 12.174 7.28253 12.0183 7.88636H14.1857C14.8972 7.88636 15.4512 8.47754 15.4545 9.12425C15.4563 9.50645 15.2938 9.91791 15.0404 10.1725L15.038 10.1748C15.2476 10.6721 15.2135 11.3688 14.8397 11.8681C15.0247 12.4198 14.8382 13.0975 14.4906 13.4609C14.5822 13.8358 14.5385 14.155 14.3597 14.4119C13.9248 15.0367 12.847 15.0455 11.9355 15.0455L11.8749 15.0454C10.8461 15.0451 10.004 14.6705 9.32745 14.3695C8.98746 14.2182 8.54291 14.031 8.20562 14.0248C8.06628 14.0222 7.95456 13.9085 7.95456 13.7691V9.21438C7.95456 9.14619 7.98188 9.08076 8.03037 9.03282C8.87442 8.19879 9.23736 7.31577 9.92918 6.62278C10.2446 6.30676 10.3593 5.8294 10.4702 5.36777C10.565 4.97357 10.7632 4.13636 11.1932 4.13636C11.7046 4.13636 12.7273 4.30682 12.7273 5.87185Z"
        fill="white"
      />
    </svg>
  );
}

export function IconWarning({ color }) {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        /* eslint-disable-next-line max-len */
        d="M20 10.5C20 16.0241 15.5225 20.5 10 20.5C4.47754 20.5 0 16.0241 0 10.5C0 4.97915 4.47754 0.5 10 0.5C15.5225 0.5 20 4.97915 20 10.5ZM10 12.5161C8.9756 12.5161 8.14516 13.3466 8.14516 14.371C8.14516 15.3954 8.9756 16.2258 10 16.2258C11.0244 16.2258 11.8548 15.3954 11.8548 14.371C11.8548 13.3466 11.0244 12.5161 10 12.5161ZM8.23899 5.84895L8.5381 11.3328C8.5521 11.5894 8.76427 11.7903 9.02125 11.7903H10.9788C11.2357 11.7903 11.4479 11.5894 11.4619 11.3328L11.761 5.84895C11.7761 5.57177 11.5554 5.33871 11.2779 5.33871H8.7221C8.44452 5.33871 8.22387 5.57177 8.23899 5.84895V5.84895Z"
        fill={color}
      />
    </svg>
  );
}

export function IconAlert({ color }) {
  return (
    <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          /* eslint-disable-next-line max-len */
          d="M21.7521 17.3286C22.4571 18.5159 21.5722 20 20.1641 20H1.8351C0.424313 20 -0.456527 18.5136 0.247091 17.3286L9.41169 1.89007C10.117 0.702235 11.8836 0.704387 12.5877 1.89007L21.7521 17.3286ZM10.9997 14.1367C10.0294 14.1367 9.24276 14.901 9.24276 15.8438C9.24276 16.7865 10.0294 17.5508 10.9997 17.5508C11.97 17.5508 12.7566 16.7865 12.7566 15.8438C12.7566 14.901 11.97 14.1367 10.9997 14.1367ZM9.33164 8.00083L9.61496 13.0477C9.62822 13.2839 9.82919 13.4688 10.0726 13.4688H11.9268C12.1702 13.4688 12.3712 13.2839 12.3844 13.0477L12.6678 8.00083C12.6821 7.74574 12.4731 7.53125 12.2101 7.53125H9.78924C9.52631 7.53125 9.31731 7.74574 9.33164 8.00083Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export const GaugeSVG = styled.svg`
  position: absolute;
  width: 20px;
  height: 52px;
  bottom: 0;
  transition: transform 0.15s ease-in-out;
  transform-origin: 9px 42px;
  transform: ${({ size, value, min, max }) => {
    const angle = -90 + (180 * value) / (max - min);
    return `scale(${size}) rotate(${angle}deg)`;
  }};
`;

export const GaugeArrow = withTheme((props) => {
  const { color, theme, ...rest } = props;
  const arrowColor = color && theme.colors[color] ? theme.colors[color] : '#595A5C';
  return (
    <GaugeSVG
      width="11"
      height="28"
      viewBox="0 0 11 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
    >
      <circle cx="5" cy="23" r="2" fill="#D7DFE5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        /* eslint-disable-next-line max-len */
        d="M4.99893 0C4.43875 0 3.97696 0.439209 3.949 0.998779L3.17443 16.4908C3.11666 17.6463 2.33715 18.6127 1.51036 19.422C0.582726 20.33 0.00909234 21.598 0.0136994 23.001C0.0228547 25.7625 2.26883 28.001 5.03018 28.001C7.79165 28.001 10.0229 25.7625 10.0137 23.001C10.0091 21.597 9.42625 20.3282 8.49139 19.42C7.66093 18.6133 6.88128 17.646 6.82346 16.4897L6.04886 0.998779C6.0209 0.439209 5.55911 0 4.99893 0ZM4.82596 20H5.15298C6.72952 20.085 7.9855 21.3901 7.99038 22.9873C7.99539 24.6392 6.66031 25.978 5.00845 25.9775C3.3566 25.9771 2.01346 24.6377 2.00857 22.9858C2.00369 21.3892 3.25088 20.085 4.82596 20Z"
        fill={arrowColor}
      />
    </GaugeSVG>
  );
});

export const SimpleGrid = styled(Grid).attrs({
  gridGap: 'DOUBLE',
  p: 'DOUBLE'
})`
  grid-template-columns: repeat(auto-fill, 200px);
`;
