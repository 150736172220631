import baseColors from '~/themes/base/colors';

export default {
  mainBackground: baseColors.white,
  mainTitle: baseColors.grey20,
  mainSubtitle: baseColors.grey10,

  widgetTitle: baseColors.grey30,
  widgetValue: baseColors.grey30,
  widgetLabel: baseColors.grey30,
  widgetBackground: baseColors.white,
  widgetBorder: baseColors.blueWhite10,
  widgetIcon: baseColors.blueWhite20,

  loadingLabel: baseColors.darkGrey20,

  gaugeBackground: baseColors.transparent,
  gaugeDefaultLine: baseColors.blueWhite10,

  lineChartLineColor: baseColors.darkBlue,
  lineChartAxisLines: baseColors.darkGrey20,
  lineChartAxisLabels: baseColors.darkGrey20,
  lineChartAxisValues: baseColors.darkGrey20,
  lineChartGridColor: baseColors.blueWhite20,

  statsValue: baseColors.darkBlue,
  statsLabel: baseColors.copyDark
};
