import styled from 'styled-components';
import { space, size, flexbox, border, grid, gridGap, width, height, color, shadow, fontSize } from 'styled-system';

export const Flex = styled.div`
  display: flex;
  box-sizing: border-box;
  ${space}
  ${size}
  ${border}
  ${flexbox}
  ${width}
  ${height}
  ${color}
  ${shadow}
`;

export const Grid = styled.div`
  display: grid;
  box-sizing: border-box;
  ${grid}
  ${gridGap}
  ${space}
  ${size}
  ${border}
`;

export const Column = styled(Flex)`
  flex-direction: column;
  position: relative;
`;

export const Image = styled.img`
  ${space}
  ${width}
`;

export const Text = styled.p`
  margin: 0;
  ${fontSize}
  ${color}
  ${space}
`;

export const Small = styled.small`
  ${fontSize}
  ${color}
  ${space}
`;
