import axios from 'axios';

const { VITE_API_ROOT } = process.env;

const request = (method, url, params, options = {}) => {
  const body = method === 'get' ? 'params' : 'data';
  const { token, api = true, ...restOpts } = options;

  const config = {
    method,
    url,
    ...(method.toLowerCase() === 'delete'
      ? {}
      : {
          [body]: params || {}
        })
  };

  if (api) {
    config.baseURL = VITE_API_ROOT;
  }

  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`
    };
  }

  return axios.request({ ...config, ...restOpts });
};

export default request;
