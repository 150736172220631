import { combineReducers } from 'redux';
import measurements from './measurements';
import nodes from './nodes';
import auth from './auth';
import app from './app';
import appVersion from './appVersion';
import dataPoints from './dataPoints';
import devices from './devices';

const reducers = {
  measurements,
  app,
  auth,
  nodes,
  appVersion,
  dataPoints,
  devices
};

export default () =>
  combineReducers({
    ...reducers
  });
