import last from 'lodash/last';
import moment from 'moment';
import round from 'lodash/round';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import findLastIndex from 'lodash/findLastIndex';
import { createSelector } from 'reselect';
import * as fromMeasurements from '~/store/reducers/measurements';
import { getDataPointById, getDefaultDataPointsIds } from '~/store/selectors/dataPoints';
import { getNodeDataAggregation } from '~/store/selectors/nodes';
import { OFFLINE_INTERVAL } from '~/utils/constants';

export const getMeasurementsById = (state, id) => fromMeasurements.getMeasurementsById(state, id);

function findIndexByTs(measurements, tsToLookup) {
  const index = findLastIndex(measurements, ([ts]) => tsToLookup >= ts);
  return index === -1 ? 0 : index + 1;
}

export const getMeasurementsLastValue = createSelector(getMeasurementsById, (measurements) => (last(measurements) || [])[1]);

function getLastMeasurementsTs(measurements) {
  return (last(measurements) || [])[0];
}

export const getMeasurementsLastValueAggregated = createSelector(
  [getMeasurementsById, getDataPointById, getNodeDataAggregation],
  (measurements, dataPoint, aggregation) => {
    if (isEmpty(measurements)) {
      return null;
    }
    if (aggregation) {
      const precision = get(dataPoint, 'round', 0);
      const lastTimeBefore = moment(getLastMeasurementsTs(measurements)).subtract(aggregation, 'minutes').valueOf();
      const lastMeasurements = measurements.slice(findIndexByTs(measurements, lastTimeBefore));
      const sum = lastMeasurements.reduce((memo, [, value]) => {
        memo += value; // eslint-disable-line no-param-reassign
        return memo;
      }, 0);
      return round(sum / lastMeasurements.length, precision);
    }
    return (last(measurements) || [])[1];
  }
);

export const getMeasurementsLastTs = createSelector(getMeasurementsById, getLastMeasurementsTs);

export const getMeasurementsValue = createSelector(getMeasurementsById, (measurements) => measurements || []);

export const getMeasurementsStat = createSelector(
  getMeasurementsById,
  (state, id, precision) => precision,
  (measurements, precision) => {
    if (!measurements || measurements.length === 0) return {};

    const allValues = measurements.map((measurement) => measurement[1]);
    const min = Math.min(...allValues);
    const max = Math.max(...allValues);

    return {
      min,
      max,
      avg: round((min + max) / 2, precision)
    };
  }
);

export const getMeasurementsByToday = createSelector(getMeasurementsById, (measurements) => {
  if (!measurements) {
    return [];
  }
  const todayIndex = findIndexByTs(measurements, moment().subtract(1, 'd').valueOf());
  return measurements.slice(todayIndex);
});

export const getLastMeasurementTs = createSelector([getDefaultDataPointsIds, fromMeasurements.getMeasurements], (ids, measurements) => {
  const timestamps = ids.reduce((map, id) => {
    const ts = getLastMeasurementsTs(measurements[id]?.data);
    if (ts) map.push(ts);
    return map;
  }, []);
  return Math.max(...timestamps);
});

export const getNodeIsOffline = (lastTs) => {
  const now = moment().valueOf();
  if (!lastTs || now - lastTs >= OFFLINE_INTERVAL) {
    return true;
  }
  return false;
};
