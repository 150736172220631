export default {
  transparent: 'transparent',
  white: '#FFFFFF',
  blueWhite10: '#D7DFE5',
  blueWhite20: '#D4DBDF',
  blueWhite50: '#A3ADBF',
  grey10: '#7A7C80',
  grey20: '#6C7382',
  grey30: '#666666',
  grey40: '#ACAFB3',
  grey90: '#595A5C',
  darkGrey10: '#575D69',
  darkGrey20: '#4A4E56',
  darkRed: '#D10404',
  red: '#FD4207',
  orange: '#F39405',
  yellow: '#FBCA1C',
  lime: '#55E124',
  green: '#04B22B',
  brightBlue: '#229ADD',
  lightBlue: '#73DDFF',
  lightBlue10: '#6298DA',
  darkBlue: '#01608F'
};
