import styled from 'styled-components';
import { Flex } from '~/components/Basic';

export const Container = styled(Flex)`
  width: 100%;
  height: ${({ height = '3px' }) => height};
  border-radius: ${({ height = '3px' }) => height};
  overflow: hidden;
`;

export const Slice = styled.div`
  height: 100%;
  background-color: ${({ color }) => color};
  width: ${({ size = 0 }) => `${size * 100}%`};
  flex: 0 0 auto;
`;
