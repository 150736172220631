import styled from 'styled-components';
import { Grid, Text, Flex } from '~/components/Basic';

export const LegendContainer = styled(Flex)`
  position: relative;
  justify-content: center;
  z-index: 10;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const ItemContainer = styled(Grid)`
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  white-space: nowrap;
  grid-gap: 5px;
  color: white;
  font-size: 18px;
  margin: 0 30px;

  @media (max-width: 768px) {
    margin: 0 15px;
  }

  @media (max-width: 748px) {
    grid-template-rows: none;
    grid-template-columns: repeat(2, auto);
    align-items: center;
  }
`;

export const LabelContainer = styled(Grid)`
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, auto);
  grid-gap: 5px;
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${({ color = 'white' }) => color};
`;

export const Label = styled(Text)`
  font-size: 1em;
  text-transform: capitalize;
`;

export const Value = styled(Text)`
  font-size: 1.25em;
  font-weight: bold;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 1.1em;
  }
`;
