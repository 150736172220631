import React from 'react';
import { connect } from 'react-redux';
import { getNodeIsOffline, getLastMeasurementTs } from '~/store/selectors/measurements';
import { getDataPointByPath } from '~/store/selectors/dataPoints';
import { getDataPointStatusById } from '~/store/selectors/profiles';
import { getNodesIdFirst, getNodeShowDashboardExposureTime } from '~/store/selectors/nodes';
import { PARAMETER_PATHS } from '~/utils/constants';
import { HeroContainer, Circle, Label, Value } from '~/components/Brand/HeroCircle/styles';
// eslint-disable-next-line import/no-unresolved
import IconAirQuality from '~/assets/icons/icon-air-quality.svg?react';

function Hero({ size, iconSize, label, value, mb, offline, showExposureTime }) {
  return (
    <HeroContainer size={size} mb={mb} bigger={!showExposureTime} status={value}>
      {!offline ? <div className="pulse" /> : undefined}
      <Circle iconSize={iconSize} status={value} offline={offline}>
        <IconAirQuality />
        <Label>{offline ? 'Device is' : label}</Label>
        <Value>{offline ? 'OFFLINE' : value}</Value>
      </Circle>
    </HeroContainer>
  );
}

const mapStateToProps = (state) => {
  const { _id: aqPointId, device_id: deviceId } = getDataPointByPath(state, PARAMETER_PATHS.AQ);
  const { status } = getDataPointStatusById(state, aqPointId, deviceId);

  const nodeId = getNodesIdFirst(state);
  const showExposureTime = getNodeShowDashboardExposureTime(state, nodeId);
  const lastTs = getLastMeasurementTs(state);
  const isOffline = getNodeIsOffline(lastTs);

  return {
    showExposureTime,
    offline: isOffline,
    value: isOffline || status
  };
};

export default connect(mapStateToProps)(Hero);
