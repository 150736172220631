export const fonts = { sansSerif: 'Source Sans Pro' };

export const scale = {
  fineprint: '14px',
  title: '18px',
  value: '24px',
  mainTitle: '28px',
  mainSubtitle: '20px'
};

export const fontSizes = {
  dashboardTitle: scale.mainTitle,
  dashboardTitleSmall: scale.title,

  dashboardNode: scale.mainSubtitle,
  dashboardNodeSmall: scale.fineprint,

  widgetTitle: scale.title,
  widgetTitleSmall: scale.title,

  widgetValue: scale.value,
  widgetValueSmall: scale.value,

  lineChartTitle: scale.mainSubtitle,
  lineChartUnits: scale.fineprint,
  lineChartStats: scale.fineprint
};
