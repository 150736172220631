import { IconAlert, IconLike, IconWarning } from '~/components/Widget/parts';

const { VITE_API_ROOT } = process.env;

export const SOCKET_URL = `wss://${VITE_API_ROOT?.split('://')[1]}socket`;

export const AQI_LABELS = {
  GOOD: 'good',
  POOR: 'poor',
  VERYPOOR: 'veryPoor',
  SATISFACTORY: 'satisfactory',
  MODERATE: 'moderate',
  EXCELLENT: 'excellent',
  OFFLINE: 'offline'
};

export const PARAMETER_PATHS = {
  AQ: '132:19',
  PM: '132:18',
  PM1: '132:4',
  PM10: '132:6',
  PM25: '132:5',
  CO2: '131:10',
  VOC_ETOH: '140:11',
  VOC_ISOBUTYLENE: '140:12',
  CH2O: '132:13',
  PRESSURE: '0:20',
  HUMIDITY: '132:15',
  TEMPERATURE_F: '132:24',
  TEMPERATURE_C: '132:14',
  TIMEZONE: '0:18'
};

export const DEFAULT_REPORTING_INTERVAL = 90000;
export const OFFLINE_INTERVAL = 60000 * 15;

export const PROFILE_VALUE_LABEL_PAIRS = [
  { value: 'moderate', label: 'Moderate', labelID: 'statistics::moderate' },
  { value: 'poor', label: 'Poor', labelID: 'statistics::poor' }
];

export const RANGES = {
  excellent: {
    color: '#1394EC',
    icon: IconLike
  },
  good: {
    color: '#1394EC',
    icon: IconLike
  },
  satisfactory: {
    color: '#FFAB20',
    icon: IconWarning
  },
  moderate: {
    color: '#FFAB20',
    icon: IconWarning
  },
  poor: {
    color: '#DE3030',
    icon: IconAlert
  },
  veryPoor: {
    color: '#DE3030',
    label: 'Very Poor',
    icon: IconAlert
  },
  offline: {
    color: '#6D6F71'
  }
};

export const IAQURE_RANGES = {
  excellent: {
    color: '#04B22B',
    icon: IconLike
  },
  good: {
    color: '#55E124',
    icon: IconLike
  },
  satisfactory: {
    color: '#FBCA1C',
    icon: IconWarning
  },
  moderate: {
    color: '#FDD455',
    icon: IconWarning
  },
  poor: {
    color: '#DE3030',
    icon: IconAlert
  },
  veryPoor: {
    color: '#D10404',
    label: 'Very Poor',
    icon: IconAlert
  },
  offline: {
    color: '#828CA0'
  }
};

export const AIRTHINX_DEFAULT_PARAMS = {
  [PARAMETER_PATHS.AQ]: {
    icon: 'airQuality',
    active: true
  },
  [PARAMETER_PATHS.PM]: {
    icon: 'pm'
  },
  [PARAMETER_PATHS.PM25]: {
    icon: 'pm'
  },
  [PARAMETER_PATHS.PM1]: {
    icon: 'pm'
  },
  [PARAMETER_PATHS.PM10]: {
    icon: 'pm'
  },
  [PARAMETER_PATHS.CO2]: {
    icon: 'carbonDioxide'
  },
  [PARAMETER_PATHS.PRESSURE]: {
    icon: 'pressure'
  },
  [PARAMETER_PATHS.CH2O]: {
    icon: 'formaldehyde'
  },
  [PARAMETER_PATHS.VOC_ETOH]: {
    icon: 'voc'
  },
  [PARAMETER_PATHS.VOC_ISOBUTYLENE]: {
    icon: 'voc'
  },
  [PARAMETER_PATHS.TEMPERATURE_F]: {
    icon: 'temperature'
  },
  [PARAMETER_PATHS.TEMPERATURE_C]: {
    icon: 'temperature'
  },
  [PARAMETER_PATHS.HUMIDITY]: {
    icon: 'humidity'
  }
};

export const AIRTHINX_IAQURE_PARAMS = {
  [PARAMETER_PATHS.PM25]: {
    icon: 'pm'
  },
  [PARAMETER_PATHS.PM1]: {
    icon: 'pm'
  },
  [PARAMETER_PATHS.PM10]: {
    icon: 'pm'
  },
  [PARAMETER_PATHS.CO2]: {
    icon: 'carbonDioxide'
  },
  [PARAMETER_PATHS.CH2O]: {
    icon: 'formaldehyde'
  },
  [PARAMETER_PATHS.VOC_ETOH]: {
    icon: 'voc'
  },
  [PARAMETER_PATHS.VOC_ISOBUTYLENE]: {
    icon: 'voc'
  },
  [PARAMETER_PATHS.HUMIDITY]: {
    icon: 'humidity'
  }
};

export const ADDITIONAL_DATA_POINTS_DEFAULT_PROFILE = [PARAMETER_PATHS.PRESSURE, PARAMETER_PATHS.TIMEZONE];
