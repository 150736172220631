import styled from 'styled-components';
import { Grid, Text } from '~/components/Basic';

export const Container = styled(Grid)`
  width: 60%;
  grid-gap: 30px;
  padding-top: 20px;
  align-items: center;
  justify-self: center;

  @media (max-width: 1200px) {
    padding-top: 40px;
  }

  @media (max-width: 1024px) {
    grid-gap: 15px;
  }

  @media (max-width: 600px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
    grid-template-columns: 100%;
  }
`;

export const Label = styled(Text)`
  font-size: 28px;
  font-weight: bold;
  color: white;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 6vw;
  }
`;
