import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';
import forOwn from 'lodash/forOwn';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import get from 'lodash/get';

import { fetchDataPointsSuccess } from '~/store/reducers/dataPoints';

export const createRequest = createAction('@@stream/CREATE_REQUEST');
export const createSuccess = createAction('@@stream/CREATE_SUCCESS');
export const createFailure = createAction('@@stream/CREATE_FAILURE');

export const updateRequest = createAction('@@stream/UPDATE_REQUEST');
export const updateSuccess = createAction('@@stream/UPDATE_SUCCESS');
export const updateFailure = createAction('@@stream/UPDATE_FAILURE');

export const destroyRequest = createAction('@@stream/DESTROY_REQUEST');
export const destroySuccess = createAction('@@stream/DESTROY_SUCCESS');
export const destroyFailure = createAction('@@stream/DESTROY_FAILURE');

export const checkRequest = createAction('@@stream/CHECK_REQUEST');

export const measurementsUpdate = createAction('@@stream/MEASUREMENTS_UPDATE');

const byIdData = {};
const byId = createReducer(byIdData, (builder) =>
  builder
    .addCase(fetchDataPointsSuccess, (state, { payload }) => {
      const {
        entities: { measurements }
      } = payload;
      if (measurements) {
        return measurements;
      }
      return state;
    })
    .addCase(measurementsUpdate, (state, { payload }) => {
      const { measurements } = payload.entities;
      if (!isEmpty(measurements)) {
        const updatedItems = {};
        forOwn(state, (item, id) => {
          const newData = get(measurements, `[${id}].data`);
          let data = isArray(item.data) ? [...item.data] : [];
          if (isArray(newData)) {
            data = [...data, ...newData];
          }
          updatedItems[id] = {
            ...item,
            data
          };
        });
        return updatedItems;
      }
      return state;
    })
);

const measurements = combineReducers({
  byId
});

const emptyMeasurements = [];
export const getMeasurements = (state) => state.measurements.byId;
export const getMeasurementsById = (state, id) => (id && state.measurements.byId[id] ? state.measurements.byId[id].data : emptyMeasurements);
export const getMeasurementsIsLoading = (state) => state.measurements.isLoading;
export const getMeasurementsIsPreloaded = (state, id) => (state.measurements.byId[id] ? state.measurements.byId[id].isLoaded : false);

export default measurements;
