import React from 'react';
import { connect } from 'react-redux';

import Overlay from '~/components/Overlay';

import { getNodesIsFetched, getIsFetchFailure } from '~/store/selectors/dataPoints';
import { getAppIsNetworkOnline, getAppIsApiOnline } from '~/store/selectors/app';

import { NoConnection } from '~/components/Overlay/styles';

function OnlineStatusOverlay(props) {
  const { isNetworkOnline, isApiOnline, isFetchFailure, children } = props;

  return (
    <>
      {!isNetworkOnline && <Overlay title="No Internet" message="Check your internet connection" icon={NoConnection} />}

      {!isApiOnline && <Overlay title="Service Unavailable" message="Service Temporarily Unavailable" icon={NoConnection} />}

      {isFetchFailure && <Overlay title="Data is not available" message="Data Temporarily Unavailable" icon={NoConnection} />}

      {children}
    </>
  );
}

const mapStateToProps = (state) => {
  const isFetched = getNodesIsFetched(state);
  const isNetworkOnline = getAppIsNetworkOnline(state);
  const isApiOnline = getAppIsApiOnline(state);
  const isFetchFailure = getIsFetchFailure(state);

  return {
    isFetched,
    isNetworkOnline,
    isApiOnline,
    isFetchFailure
  };
};

export default connect(mapStateToProps)(OnlineStatusOverlay);
