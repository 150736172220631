import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getAppCurrentParameter } from '~/store/selectors/app';
import { getDataPointByPath } from '~/store/selectors/dataPoints';
import { getNodeDataAggregation } from '~/store/selectors/nodes';
import { getDataPointStatusById } from '~/store/selectors/profiles';
import { getMeasurementsLastValueAggregated, getNodeIsOffline, getLastMeasurementTs } from '~/store/selectors/measurements';
import SingleParam from '~/components/Brand/Parameters/SingleParam';

const mapStateToProps = (state, ownProps) => {
  const { path, t } = ownProps;
  const selectedDataPointPath = getAppCurrentParameter(state);
  const { _id: dataPointId, device_id: deviceId, unit, name, node_id: nodeId } = getDataPointByPath(state, path);
  const { status } = getDataPointStatusById(state, dataPointId, deviceId);
  const lastTs = getLastMeasurementTs(state);
  const aggregation = getNodeDataAggregation(state, nodeId);
  const isOffline = getNodeIsOffline(lastTs);
  const label = t(`${path}_short`, name);
  return {
    status,
    isOffline,
    label,
    selectedDataPointPath,
    value: getMeasurementsLastValueAggregated(state, dataPointId, aggregation),
    units: unit
  };
};

export default withTranslation('parameters')(connect(mapStateToProps)(SingleParam));
