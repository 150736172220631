import styled from 'styled-components';
import { Flex, Column, Image } from '~/components/Basic';
import { Text } from '~/components/Basic/typography';

// eslint-disable-next-line
const icnNoConnection =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMzIiBoZWlnaHQ9IjEwMiIgdmlld0JveD0iMCAwIDEzMyAxMDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTAyLjU3NyA1MUgxMDcuNUMxMDkuMjcyIDUxIDExMC45NzQgNTEuMjk3MyAxMTIuNTYgNTEuODQ0N0MxMTguNjM2IDUzLjk0MjEgMTIzIDU5LjcxMTQgMTIzIDY2LjVDMTIzIDc0LjQwMDEgMTE3LjA5IDgwLjkxOTcgMTA5LjQ1IDgxLjg3ODZMMTE3LjUyNSA4OS45NTM5QzEyNi42MjQgODYuMDU5NCAxMzMgNzcuMDI0MiAxMzMgNjYuNUMxMzMgNTQuMjQ1MyAxMjQuMzU1IDQ0LjAwOTYgMTEyLjgzMiA0MS41NTgyQzExMC44MzQgMTguMjc0OSA5MS4zMDEgMCA2Ny41IDBDNTYuMzQ3OCAwIDQ2LjE0MDYgNC4wMTA3MiAzOC4yMzMxIDEwLjY2MjFMNDUuMzM3MyAxNy43NjYzQzUxLjQxMTEgMTIuOTA2MyA1OS4xMTYyIDEwIDY3LjUgMTBDODUuNTgxOCAxMCAxMDAuNTA2IDIzLjUxODUgMTAyLjcxNyA0MUMxMDIuOTA0IDQyLjQ3MzcgMTAzIDQzLjk3NTcgMTAzIDQ1LjVDMTAzIDQ3LjM3MDcgMTAyLjg1NSA0OS4yMDc1IDEwMi41NzcgNTFaTTMzLjQ1MSAzMy4wMjIxQzIwLjQwNjYgMzMuNTcxNSAxMCA0NC4zMjA1IDEwIDU3LjVDMTAgNzEuMDMxIDIwLjk2OSA4MiAzNC41IDgySDU1LjVINjQuNUg4Mi40Mjg5TDMzLjQ1MSAzMy4wMjIxWk05Mi40Mjg5IDkySDY0LjVINTUuNUgzNC41QzE1LjQ0NjIgOTIgMCA3Ni41NTM4IDAgNTcuNUMwIDQxLjgxMDcgMTAuNDcyMSAyOC41NjgxIDI0LjgwODkgMjQuMzhMMTUuNDY0NSAxNS4wMzU1TDIyLjUzNTUgNy45NjQ0OEwxMDguNTM2IDkzLjk2NDVMMTAxLjQ2NCAxMDEuMDM2TDkyLjQyODkgOTJaIiBmaWxsPSIjRDEwNDA0Ii8+Cjwvc3ZnPgo=';

export const Container = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center'
})`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.35);
`;

export const MessageContainer = styled(Column).attrs({
  bg: 'widgetBackground',
  p: 'QUAD'
})`
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  align-items: center;
`;

export const Title = styled(Text).attrs({
  fontSize: ['20px', '24px', '24px', '28px'],
  fontWeight: 'bold',
  color: 'widgetTitle'
})``;

export const Body = styled(Text).attrs({
  fontsize: ['14px', '16px', '16px', '18px'],
  color: 'widgetLabel'
})``;

export const NoConnection = styled(Image).attrs({
  src: icnNoConnection,
  width: '8em',
  height: 'auto'
})`
  filter: brightness(1.1);
`;
