import { createSelector } from 'reselect';
import isFinite from 'lodash/isFinite';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import get from 'lodash/get';
import head from 'lodash/head';
import camelCase from 'lodash/camelCase';
import sortBy from 'lodash/sortBy';
import * as fromDevices from '~/store/selectors/devices';
import * as fromMeasurements from '~/store/selectors/measurements';
import * as fromDatapoints from '~/store/selectors/dataPoints';
import { AQI_LABELS } from '~/utils/constants';

export const getProfile = createSelector(fromDevices.getFirstDevice, (device) => device?.profiles[0]);

export const getProfileRawRanges = createSelector(fromDatapoints.getDataPointById, getProfile, (dataPoint, profile) => {
  const path = fromDatapoints.getPath(dataPoint);
  return get(profile, `ranges[${path}]`);
});

export const getProfilePaths = createSelector(getProfile, (profile) => Object.keys(profile?.ranges));

export const getVirtualRange = (value, status) => {
  const rangeValue = isFinite(value) ? value : 0;
  const min = rangeValue - 1;
  const max = rangeValue + 1;

  return {
    ranges: [
      {
        from: min,
        to: max,
        ...{ status }
      }
    ],
    min,
    max
  };
};

export const getProfileRanges = createSelector(getProfileRawRanges, (ranges) => {
  if (ranges) {
    return ranges.filter(({ gte, lte, gt, lt }) => !(isNil(gt) && isNil(gte) && isNil(lt) && isNil(lte)));
  }

  return undefined;
});

export const getProfileDefaultStatus = createSelector(getProfileRawRanges, getProfile, (ranges, profile) => {
  if (!ranges) return undefined;

  const lastRange = last(ranges);

  if (!lastRange) {
    return undefined;
  }

  const { gte, lte, gt, lt } = lastRange;
  if (!(isNil(gt) && isNil(gte) && isNil(lt) && isNil(lte))) {
    return undefined;
  }

  return camelCase(profile.status_name[lastRange.status]);
});

export const getDataPointStatusById = createSelector(
  getProfileRawRanges,
  fromMeasurements.getMeasurementsLastValueAggregated,
  getProfile,
  (ranges, value, profile) => {
    if (isNil(ranges) || isNil(value)) {
      return {};
    }

    const selectedRange =
      ranges.find((range) => {
        const { gte, lte, gt, lt } = range;
        const exp = [];
        if (!isNil(gte)) exp.push(`value >= ${gte}`);
        if (!isNil(lte)) exp.push(`value <= ${lte}`);
        if (!isNil(gt)) exp.push(`value > ${gt}`);
        if (!isNil(lt)) exp.push(`value < ${lt}`);
        return eval(exp.join(' && ')); // eslint-disable-line no-eval
      }) || last(ranges);

    const status = profile.status_name[selectedRange.status];

    return {
      status: camelCase(status)
    };
  }
);

const sortRanges = (ranges) => {
  const normalizedRanges = ranges.map((range) => {
    const { gte, lte, gt, lt } = range;
    const normalizedRange = { ...range };

    if ((!isNil(gt) || !isNil(gte)) && (!isNil(lt) || !isNil(lte))) {
      const min = !isNil(gt) ? gt : gte;
      const max = !isNil(lt) ? lt : lte;

      normalizedRange.avg = (min + max) / 2;
    } else {
      normalizedRange.avg = [gte, lte, gt, lt].find((value) => !isNil(value));
    }

    return normalizedRange;
  });

  return sortBy(normalizedRanges, ['avg']);
};

const convertRanges = (ranges, profile, value) =>
  ranges.map((range) => {
    const { gte, lte, gt, lt } = range;
    const convertedRange = {
      status: camelCase(profile.status_name[range.status])
    };

    if (!isNil(gt)) convertedRange.from = gt;
    if (!isNil(gte)) convertedRange.from = gte;
    if (!isNil(lt)) convertedRange.to = lt;
    if (!isNil(lte)) convertedRange.to = lte;

    if (isNil(convertedRange.from)) {
      if (convertedRange.to < value) {
        convertedRange.from = convertedRange.to;
      } else {
        convertedRange.from = value;
      }
    }

    if (isNil(convertedRange.to)) {
      if (convertedRange.from > value) {
        convertedRange.to = convertedRange.from;
      } else {
        convertedRange.to = value;
      }
    }

    return convertedRange;
  });

const trimRanges = (ranges, value, defaultStatus) => {
  if (ranges.length < 4) {
    return ranges;
  }
  const indexRange = ranges.findIndex((range) => range.from <= value && range.to >= value);

  if (indexRange === -1) {
    // out of the range
    if (isNil(defaultStatus)) {
      return [last(ranges)]; // return last range if don't have a default status
    }
    return undefined;
  }
  if (indexRange === 0) {
    // start of the range
    return ranges.slice(0, 3);
  }
  if (indexRange === ranges.length - 1) {
    // end of the range
    return ranges.slice(ranges.length - 3);
  }

  return ranges.slice(indexRange - 1, indexRange + 2); // middle of the range
};

export const getDataPointRangesById = createSelector(
  getProfileRanges,
  getProfileDefaultStatus,
  fromMeasurements.getMeasurementsLastValue,
  getProfile,
  (ranges, defaultStatus, value, profile) => {
    if (isNil(ranges) || isNil(value)) {
      return getVirtualRange(value);
    }

    const sortedRanges = sortRanges(ranges);

    const convertedRanges = convertRanges(sortedRanges, profile, value);

    const trimmedRange = trimRanges(convertedRanges, value, defaultStatus);

    if (!trimmedRange) {
      return getVirtualRange(value, defaultStatus);
    }

    return {
      ranges: trimmedRange,
      min: head(trimmedRange).from,
      max: last(trimmedRange).to
    };
  }
);

export const calculateStatusForValue = (ranges, value, profile) => {
  if (isNil(ranges) || isNil(value)) {
    return {};
  }

  const selectedRange =
    ranges.find((range) => {
      const { gte, lte, gt, lt } = range;
      const exp = [];
      if (!isNil(gte)) exp.push(`value >= ${gte}`);
      if (!isNil(lte)) exp.push(`value <= ${lte}`);
      if (!isNil(gt)) exp.push(`value > ${gt}`);
      if (!isNil(lt)) exp.push(`value < ${lt}`);
      return eval(exp.join(' && ')); // eslint-disable-line no-eval
    }) || last(ranges);

  const status = profile.status_name[selectedRange.status];

  return {
    status: camelCase(status)
  };
};

export const getStatisticsQualityNumbers = createSelector(
  getProfile,
  getProfileRawRanges,
  fromMeasurements.getMeasurementsByToday,
  (profile, ranges, measurements) => {
    if (!profile) {
      return null;
    }

    const fullExpectedPeriod = 24 * 60;

    const result = measurements.reduce(
      (map, item) => {
        const { status } = calculateStatusForValue(ranges, item[1], profile);
        map[status] += 1; // eslint-disable-line no-param-reassign
        return map;
      },
      {
        [AQI_LABELS.POOR]: 0,
        [AQI_LABELS.MODERATE]: 0,
        [AQI_LABELS.GOOD]: 0,
        [AQI_LABELS.OFFLINE]: 0
      }
    );

    result[AQI_LABELS.OFFLINE] += fullExpectedPeriod - measurements.length;
    if (result[AQI_LABELS.OFFLINE] < 0) {
      result[AQI_LABELS.OFFLINE] = 0;
    }

    const mapped = Object.entries(result)
      .filter(([key]) => AQI_LABELS[key.toUpperCase()])
      .map(([key, value]) => {
        value = +((value / fullExpectedPeriod) * 100).toFixed(1); // eslint-disable-line no-param-reassign
        return {
          label: key,
          value
        };
      });
    return mapped;
  }
);
