import styled from 'styled-components';
import { Grid } from '~/components/Basic';

export const Container = styled(Grid)`
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px 80px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 1400px) {
    grid-gap: 20px 40px;
  }

  @media (max-width: 1200px) {
    grid-gap: 40px 10em;
  }

  @media (max-width: 992px) {
    grid-gap: 40px;
  }

  @media (max-width: 768px) {
    max-width: 315px;
  }

  @media (max-height: 768px) {
    grid-gap: 20px;
    padding-top: 20px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    grid-template-columns: repeat(2, 45%);
    width: 100%;
    padding: 0;
  }

  @media (max-width: 390px) {
    grid-template-columns: repeat(2, 50%);
  }
`;
