import styled from 'styled-components';
import { Column, Flex, Grid, Image } from '~/components/Basic';
import imgWaves from '~/assets/waves.svg';

export const FullPage = styled(Column)`
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 100vh;
  background: ${({ offline = false }) =>
    offline
      ? 'linear-gradient(180deg, #616C77 6.77%, #525962 29.01%, #3F3F3F 52.96%, #303438 87.71%, #363D45 96.36%)'
      : 'linear-gradient(180deg, #1b4373 0%, #225a9e 100%), rgba(0, 0, 0, 0.1)'};
`;

export const CentralPart = styled(Grid)`
  grid-gap: 50px;
  width: 60%;
  max-width: 800px;
  align-items: center;
  justify-content: center;
  grid-template-columns: 100%;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  padding-top: 6vh;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 1280px) {
    width: 100%;
    padding: 5vh;
    padding-top: 8vh;
    padding-bottom: 1.5rem;
  }

  @media (max-width: 1200px) {
    padding-bottom: 0px;
  }

  @media (max-width: 1100px) {
    display: grid;
    grid-gap: 20px;
    grid-template-rows: minmax(200px, 1fr) repeat(3, auto);
  }

  @media (max-width: 768px) {
    grid-gap: 1.5rem;
  }

  @media (max-width: 480px) {
    height: auto;
    padding: 1.5rem;
    padding-top: 4rem;
    min-height: auto;
    grid-template-rows: minmax(200px, 1fr) auto;
  }

  @media (max-height: 720px) {
    max-width: 100%;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: ${({ height = '2px' }) => height};
  opacity: ${({ opacity = 0.15 }) => opacity};
  background-color: white;

  @media (min-width: 1200px) {
    display: none;
  }
`;

export const Waves = styled(Image).attrs({
  src: imgWaves
})`
  width: auto;
  height: 200%;
  position: absolute;
  z-index: 0;
  transform: rotate(-15deg);
  opacity: 0.02;
  user-select: none;
  pointer-events: none;
`;

export const TwoWaySplit = styled(Flex)`
  width: 100%;
  padding: 0 20px;
  margin-bottom: 0;
  margin-top: 5vh;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    padding: 0;
    flex-direction: column;
    align-items: center;
  }

  @media (max-height: 866px) {
    margin-top: 100px;
  }

  @media (max-width: 1200px) and (max-height: 866px) {
    margin-top: 5vh;
  }
`;

export const RightPart = styled(Flex)`
  width: 40%;
  max-width: 600px;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${({ offline = false }) => (offline ? 'transparent' : 'rgba(23, 68, 122, 0.8)')};
  align-self: center;
  position: relative;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 20px 5vh 5vh 5vh;
    padding-bottom: 30px;
    background-color: transparent;
  }
`;

export const ParametersBlock = styled(Flex)`
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  padding: 2rem;

  @media (max-width: 1200px) {
    padding: 0;
  }
`;
