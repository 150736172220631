import { put, take, call, all, fork, select, race, cancelled } from 'redux-saga/effects';
import indexOf from 'lodash/indexOf';
import { getAppCurrentParameter } from '~/store/selectors/app';
import { getNodeDefaultParameters, getNodesIdFirst } from '~/store/selectors/nodes';
import { createInterval } from './utils';
import { selectParameter, startRotatingParameters, stopRotatingParameters } from '../reducers/app';

const PARAMETERS_ROTATION_INTERVAL = 15000;

function createIntervalRotating() {
  return createInterval(PARAMETERS_ROTATION_INTERVAL);
}

export function* startRotatingSaga() {
  const chan = yield call(createIntervalRotating);
  const nodeId = yield select(getNodesIdFirst);
  const params = yield select(getNodeDefaultParameters, nodeId);
  let selected = yield select(getAppCurrentParameter);
  if (!selected) {
    yield put(selectParameter(params[0].path));
  }
  try {
    while (true) {
      yield take(chan);
      selected = yield select(getAppCurrentParameter);
      const index = indexOf(
        params.map((item) => item.path),
        selected
      );
      const nextParameter = params[index + 1] ? params[index + 1].path : params[0].path;
      yield put(selectParameter(nextParameter));
    }
  } finally {
    if (yield cancelled()) {
      chan.close();
    }
  }
}

export function* watchStartRotate() {
  while (true) {
    yield take(startRotatingParameters);
    yield race([call(startRotatingSaga), take(stopRotatingParameters)]);
  }
}

export default function* rotating() {
  yield all([fork(watchStartRotate)]);
}
