import React from 'react';
import kebabCase from 'lodash/kebabCase';
import { formatMinutes } from '~/utils/helpers';
import { ItemContainer, LabelContainer, Dot, Label, Value, LegendContainer } from './styles';

function LegendItem({ color, label, value }) {
  const minutes = Math.round((1440 * value) / 100);

  if (minutes > 2) {
    return (
      <ItemContainer>
        <LabelContainer>
          <Dot color={color} />
          <Label>{label}</Label>
        </LabelContainer>
        <Value>{formatMinutes(minutes)}</Value>
      </ItemContainer>
    );
  }

  return null;
}

function Legend({ values }) {
  const nonEmptyValues = values.filter(({ value }) => value >= 0.04);
  return (
    <LegendContainer numberOfItems={nonEmptyValues.length || undefined}>
      {nonEmptyValues.map(({ color, label, value }) => (
        <LegendItem key={`${kebabCase(label)}Item`} color={color} label={label} value={value} />
      ))}
    </LegendContainer>
  );
}

export default Legend;
