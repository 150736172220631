import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const fetchDataPointsRequest = createAction('@@dataPoints/FETCH_REQUEST');
export const fetchDataPointsSuccess = createAction('@@dataPoints/FETCH_SUCCESS');
export const fetchDataPointsFailure = createAction('@@dataPoints/FETCH_FAILURE');

const byIdData = {};
const byId = createReducer(byIdData, (builder) =>
  builder.addCase(fetchDataPointsSuccess, (state, { payload }) => {
    const {
      entities: { data_points: dataPoints }
    } = payload;
    if (dataPoints) {
      return dataPoints;
    }
    return state;
  })
);

const dataPoints = combineReducers({
  byId
});

export const getDataPoint = (state, id) => (id ? state.dataPoints.byId[id] : undefined);

export const getDataPoints = (state) => state.dataPoints.byId;

export default dataPoints;
