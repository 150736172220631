import React from 'react';
import { Container, Slice } from './styles';

function FillChart(props) {
  const { slices, height } = props;
  return (
    <Container height={height}>
      {slices.map(({ color, size }) => (
        <Slice key={`${color}Item`} color={color} size={size} />
      ))}
    </Container>
  );
}

export default FillChart;
