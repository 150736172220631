import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
  flex: 1;
  min-height: 20vh;

  @media (max-width: 1920px) {
    min-height: 15vh;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;
