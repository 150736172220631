import round from 'lodash/round';
import last from 'lodash/last';
import isNumber from 'lodash/isNumber';
import { getAvgDecimalsPoint } from '~/utils/helpers';

export default {
  title: {
    text: null
  },
  chart: {
    defaultSeriesType: 'spline',
    backgroundColor: 'transparent',
    spacingLeft: 25,
    spacingRight: 10,
    spacingBottom: 10,
    spacingTop: 10
  },
  colors: ['#73DDFF'],
  plotOptions: {
    line: {
      marker: {
        enabled: false
      }
    },
    series: {
      lineWidth: 3,
      dataGrouping: {
        forced: true,
        units: [
          ['minute', [15, 30]],
          ['hour', [1, 2, 3, 4, 6, 8, 12]]
        ]
      }
    }
  },
  series: [
    {
      showInLegend: false
    }
  ],
  credits: {
    enabled: false
  },
  xAxis: {
    type: 'datetime',
    minRange: 1000,
    lineWidth: 0,
    tickLength: 0,
    gridLineWidth: 1,
    gridLineColor: '#6298DA',
    minPadding: 0,
    maxPadding: 0,
    overscroll: 0,
    title: {
      text: null
    },
    opposite: false,
    labels: {
      overflow: 'justify',
      style: {
        color: '#6298DA',
        fontWeight: 'bold',
        fontSize: '14px'
      }
    }
  },
  tooltip: {
    enabled: true,
    crosshairs: true,
    shared: true,
    useHTML: true,
    headerFormat: '<table style="border-spacing: 2px;border-collapse: separate;"><tr><td colSpan="2"><small>{point.key}</small></td></tr>',
    pointFormatter() {
      const precision = isNumber(this.series.options.round) ? this.series.options.round : getAvgDecimalsPoint(last(this.series.yData, 5));
      let value = this.y;
      value = +precision > 0 ? round(value, precision) : round(value);
      return `<tr> \
<td style="color: ${this.series.color}">${this.series.name}: </td> \
<td><b>${value}</b></td> \
</tr>`;
    },
    footerFormat: '</table>',
    xDateFormat: '%a, %e of %B, %H:%M:%S'
  },
  yAxis: {
    gridLineWidth: 10,
    visible: false,
    title: {
      text: null
    },
    opposite: false,
    labels: {
      enabled: false
    }
  },
  legend: {
    enabled: true
  },
  rangeSelector: {
    enabled: false
  },
  navigator: {
    enabled: false,
    outlineWidth: 0
  },
  scrollbar: {
    enabled: false
  }
};
