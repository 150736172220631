import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import { fetchNodesSuccess } from '~/store/reducers/nodes';

const byIdData = {};
const byId = createReducer(byIdData, (builder) =>
  builder.addCase(fetchNodesSuccess, (state, action) => {
    if (action.payload.entities.devices) {
      return action.payload.entities.devices;
    }
    return state;
  })
);

const devices = combineReducers({
  byId
});

export const getDevices = (state) => state.devices.byId;

export const getDevicesById = (state, id) => (id ? state.devices.byId[id] : null);

export default devices;
