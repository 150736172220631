import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(540deg);
  }
`;

export const IconContainer = styled.svg`
  width: 18px;
  height: auto;
  margin-right: ${({ theme }) => `${theme.space.BASE}px`};
  animation: ${rotate} 2.5s ease-in-out infinite;
`;
