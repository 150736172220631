import { normalize } from 'normalizr';
import _ from 'lodash';
import isNaN from 'lodash/isNaN';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import memoize from 'lodash/memoize';

export function normalizeResp(data, schema) {
  // eslint-disable-next-line no-prototype-builtins
  return normalize(data.hasOwnProperty('data') ? data.data : data, schema);
}

export function normalizeDataPointsResp(dataPoints) {
  if (!Array.isArray(dataPoints)) dataPoints = [dataPoints]; // eslint-disable-line
  return dataPoints.map((dp) => ({
    ...dp,
    measurements: { data: dp.measurements, data_point_id: dp._id } // eslint-disable-line no-underscore-dangle
  }));
}

export function normalizeNodesResp(nodes) {
  return nodes.map((node) => {
    if (node.data_points) {
      // eslint-disable-next-line no-param-reassign
      node.data_points = normalizeDataPointsResp(node.data_points);
    }
    return node;
  });
}

export function isDefined(value) {
  return !isUndefined(value) && !isNull(value);
}

export function isBlank(string) {
  return isUndefined(string) || isNull(string) || string.toString().trim().length === 0;
}

export function isBlankString(str) {
  return !isDefined(str) || /^\s*$/.test(str);
}

export function getEnv() {
  return import.meta.env.MODE === 'production' ? 'environet' : 'envdev';
}

export function getActionName(action, options = {}) {
  const { separator = '/', prefix } = options;
  return prefix ? `${prefix}${separator}${action}` : action;
}

export function getStatusActionNames(actionName, options) {
  return ['REQUEST', 'SUCCESS', 'FAILURE'].reduce((result, status) => {
    const action = `${actionName}_${status}`;
    // eslint-disable-next-line no-param-reassign
    result[action] = getActionName(action, options);
    return result;
  }, {});
}

export function getActionsWithoutPrefix(actions, prefix) {
  return actions.map((action) => action.slice(prefix.length + 1));
}

export function isProduction() {
  return process.env.NODE_ENV === 'production';
}

export const formatMinutes = memoize((minutes) => {
  const remainingMinutes = minutes % 60;
  const hours = (minutes - remainingMinutes) / 60;
  if (hours > 23) {
    let remainingHours = hours % 24;
    const days = (hours - remainingHours) / 24;

    // round up to have 7 days in total instead of 6days and 23 hours
    if (remainingMinutes >= 30) {
      remainingHours += 1;
    }
    if (days) {
      if (remainingHours) {
        return `${days}d ${remainingHours}h`;
      }
      return `${days}d`;
    }
  }
  if (hours) {
    if (remainingMinutes) {
      return `${hours}h ${remainingMinutes}m`;
    }
    return `${hours}h`;
  }
  return `${remainingMinutes}m`;
});

export function flatJson(obj) {
  return Object.assign({}, ...Object.keys(obj).map((k) => ({ [k]: JSON.stringify(obj[k]) })));
}

export function prepareStateForSentry(state = {}) {
  return {
    ...state,
    measurements: {
      ...state.measurements,
      byId: reduce(
        get(state, 'measurements.byId', {}),
        (result, { data, ...rest }) => {
          result = { ...result, ...rest }; // eslint-disable-line no-param-reassign
          result.data = data?.slice(0, 10); // eslint-disable-line no-param-reassign
          return result;
        },
        {}
      )
    }
  };
}

export function prepareActionForSentry(action = {}) {
  if (get(action, 'payload.entities.measurements')) {
    return {
      ...action,
      payload: {
        ...action.payload,
        entities: {
          ...action.payload.entities,
          measurements: reduce(
            action.payload.entities.measurements,
            (result, { data }) => {
              result.data = data?.slice(0, 10); // eslint-disable-line no-param-reassign
              return result;
            },
            {}
          )
        }
      }
    };
  }
  return action;
}

export function getAvgDecimalsPoint(values) {
  const decimalsArr = _(values)
    .chain()
    .map((v) => `${v}`.split('.')[1])
    .compact()
    .value();
  const sum = _(decimalsArr).reduce((memo, num) => memo + num.length, 0);
  const decimals = Math.round(sum / decimalsArr.length);
  if (isNaN(decimals)) {
    return 0;
  }
  return decimals;
}

export function getReleaseName() {
  if (process.env.GITHUB_REF_TYPE === 'tag') {
    return process.env.GITHUB_REF_NAME;
  }
  return process.env.GITHUB_SHA;
}
