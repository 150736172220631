import React from 'react';
import SingleParam from '~/containers/SingleParam';
import { Container } from './styles';

function ParametersGrid(props) {
  const { params, layout } = props;
  return (
    <Container className={layout}>
      {params.map(({ icon, active, path }) => (
        <SingleParam key={path} icon={icon} active={active} path={path} />
      ))}
    </Container>
  );
}

export default ParametersGrid;
