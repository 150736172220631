import * as fromApp from '~/store/reducers/app';

export function getAppIsRotating(state) {
  return fromApp.getAppIsRotating(state);
}

export function getAppCurrentParameter(state) {
  return fromApp.getAppCurrentParameter(state);
}

export function getAppIsNetworkOnline(state) {
  return fromApp.getAppIsNetworkOnline(state);
}

export function getAppIsApiOnline(state) {
  return fromApp.getAppIsApiOnline(state);
}
