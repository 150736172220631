import React from 'react';
import { useDispatch } from 'react-redux';
import { selectParameter, startRotatingParameters, stopRotatingParameters } from '~/store/reducers/app';
import { AQI_LABELS } from '~/utils/constants';
import getIcon from '~/components/Icon';
import { Container, TextContainer, Label, Value, IconBlock } from './styles';

function SingleParam(props) {
  const { label, value, units, status, path, selectedDataPointPath, isOffline } = props;
  const Icon = getIcon(path);
  const dispatch = useDispatch();
  const show = status === AQI_LABELS.POOR || status === AQI_LABELS.MODERATE;

  const handleClick = () => {
    dispatch(selectParameter(path));
    dispatch(stopRotatingParameters());
    dispatch(startRotatingParameters());
  };

  return (
    <Container active={path === selectedDataPointPath} offline={isOffline} onClick={handleClick}>
      <IconBlock status={status} show={show} offline={isOffline || undefined}>
        <Icon />
      </IconBlock>
      <TextContainer>
        <Label status={status}>{label}</Label>
        <Value>
          {value != null ? value : 'N/A'}
          {value != null && units && <span>{units}</span>}
        </Value>
      </TextContainer>
    </Container>
  );
}

export default SingleParam;
