import styled from 'styled-components';
import { Text } from '~/components/Basic';

export const IconContainer = styled.div`
  fill: #6298da;
  width: 12px;
  height: 12px;
  margin-bottom: 6px;
  margin-right: 5px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Container = styled(Text)`
  right: 10px;
  display: flex;
  color: #6298da;
  bottom: 10px;
  font-size: 16px;
  position: absolute;
  align-items: center;
`;

export const Tooltip = styled(Text).attrs({
  className: 'tooltip'
})`
  left: 50%;
  width: auto;
  display: flex;
  padding: 5px 15px;
  white-space: pre;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  pointer-events: none;
  transition: all 0.3s ease;
  bottom: calc(100% + 5px);
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  opacity: ${({ open }) => (open ? '1' : '0')};

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -18px;
    transform: translateX(-50%);
    border: 8px solid transparent;
    border-top: 10px solid rgba(0, 0, 0, 0.7);
  }
`;
