import styled from 'styled-components';
import { Flex } from '~/components/Basic';
import { Text } from '~/components/Basic/typography';

export const Container = styled(Flex).attrs({
  width: '100%',
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
})``;

export const TextBlock = styled(Flex).attrs({
  alignItems: 'center',
  color: 'loadingLabel'
})``;

export const Label = styled(Text).attrs({})``;
