import styled from 'styled-components';
import { Image } from '~/components/Basic';
import airthingLogoWhite from '~/assets/logo-white.png';

export const LogoLight = styled(Image).attrs({
  src: airthingLogoWhite
})`
  width: 15%;
  max-width: 170px;
  height: auto;
  position: absolute;
  top: 3rem;
  right: 3rem;
  z-index: 15;

  @media (max-width: 1024px) {
    width: 15%;
    top: 1.5rem;
    right: 1.5rem;
  }
  @media (max-width: 768px) {
    width: 25%;
  }
`;
