import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import omit from 'lodash/omit';
import flatten from 'lodash/flatten';
import difference from 'lodash/difference';
import { PARAMETER_PATHS, AIRTHINX_DEFAULT_PARAMS } from '~/utils/constants';
import * as fromNodes from '~/store/reducers/nodes';

export function getNodesIds(state) {
  return fromNodes.getIds(state);
}

export function getNodesById(state, id) {
  const node = fromNodes.getNode(state, id);
  return node || fromNodes.getNode(state, getNodesIds(state)[0]);
}

export const getNodesByIdDeviceId = createSelector(getNodesById, (node = {}) => node.devices[0]);

export const getNodeByIdName = createSelector(getNodesById, (node = {}) => node.name);

export const getNodesIdFirst = createSelector(getNodesIds, (nodes) => nodes[0]);

export const getNodeUserMetadata = createSelector(getNodesById, (node) => node?.user_metadata || {});

export const getNodeDataAggregation = createSelector(getNodeUserMetadata, (userMetadata) => userMetadata.dashboard_data_aggregation);

export const getNodeShowDashboardExposureTime = createSelector(getNodeUserMetadata, (userMetadata) => userMetadata.dashboard_exposure_time !== false);

export const getNodeTemperatureUnits = createSelector([getNodeUserMetadata], (userMetadata) => {
  const { dashboard_temp_units: tempUnits } = userMetadata;
  if (includes([PARAMETER_PATHS.TEMPERATURE_F, PARAMETER_PATHS.TEMPERATURE_C], tempUnits)) {
    return tempUnits;
  }
  return PARAMETER_PATHS.TEMPERATURE_F;
});

export const getNodeVocUnits = createSelector(getNodeUserMetadata, (userMetadata) => {
  const { dashboard_voc_units: vocUnits } = userMetadata;
  if (includes([PARAMETER_PATHS.VOC_ETOH, PARAMETER_PATHS.VOC_ISOBUTYLENE], vocUnits)) {
    return vocUnits;
  }
  return PARAMETER_PATHS.VOC_ISOBUTYLENE;
});

export const getNodePmUnits = createSelector([getNodeUserMetadata], (userMetadata) => {
  const { dashboard_pm_units: pmUnits = [] } = userMetadata;
  if (intersection([PARAMETER_PATHS.PM1, PARAMETER_PATHS.PM10, PARAMETER_PATHS.PM25], pmUnits).length) {
    return pmUnits;
  }
  return [PARAMETER_PATHS.PM];
});

export const getNodeDefaultParameters = createSelector([getNodeTemperatureUnits, getNodeVocUnits, getNodePmUnits], (tempUnits, vocUnits, pmUnits) => {
  const omitParams = flatten([
    difference([PARAMETER_PATHS.TEMPERATURE_C, PARAMETER_PATHS.TEMPERATURE_F], [tempUnits]),
    difference([PARAMETER_PATHS.VOC_ETOH, PARAMETER_PATHS.VOC_ISOBUTYLENE], [vocUnits]),
    difference([PARAMETER_PATHS.PM, PARAMETER_PATHS.PM1, PARAMETER_PATHS.PM10, PARAMETER_PATHS.PM25], pmUnits)
  ]);
  const params = omit(AIRTHINX_DEFAULT_PARAMS, ...omitParams);
  return Object.keys(params).map((path) => ({ path, ...params[path] }));
});
