import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

export const checkNewRelease = createAction('@@appVersion/CHECK_NEW_RELEASE');
export const currentAppVersion = createAction('@@appVersion/CURRENT_APP_VERSION');

const current = createReducer(null, (builder) => builder.addCase(currentAppVersion, (state, { payload }) => payload));

export const getCurrentAppVersion = (state) => state.appVersion.current;

export default combineReducers({
  current
});
