import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '~/locales/en';

const availableResources = {
  en
};

export const availableLanguages = Object.keys(availableResources);

i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: availableResources,
  nsSeparator: '::',
  keySeparator: false,
  interpolation: { prefix: '%{', suffix: '}', escapeValue: false }
});

export default i18next;
