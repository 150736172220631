import React from 'react';
import Logo from '~/components/Logo';
import LoadingIcon from '~/components/Loading/LoadingIcon';
import { Container, TextBlock, Label } from './styles';

function Loading(props) {
  const { logo } = props;
  return (
    <Container>
      <Logo src={logo} height="3rem" mb="DOUBLE" />
      <TextBlock>
        <LoadingIcon />
        <Label>Loading data, please wait...</Label>
      </TextBlock>
    </Container>
  );
}

export default Loading;
