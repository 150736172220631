import styled from 'styled-components';
import { Column, Text } from '~/components/Basic';
import { AQI_LABELS } from '~/utils/constants';

export const CircleColor = {
  [AQI_LABELS.GOOD]: '#1789F2',
  [AQI_LABELS.MODERATE]: '#FFAB20',
  [AQI_LABELS.POOR]: '#ee3c3c',
  [AQI_LABELS.OFFLINE]: '#5f6a75'
};

export const HeroContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  position: relative;
  --max-size: ${({ bigger }) => (bigger ? '50vh' : '40vh')};
  max-width: var(--max-size);
  max-height: var(--max-size);
  min-width: 192px;
  min-height: 192px;
  width: ${({ size = '200px', bigger }) => (bigger ? '60vh' : size)};
  height: ${({ size = '200px', bigger }) => (bigger ? '60vh' : size)};
  border-radius: ${({ size = '200px', bigger }) => (bigger ? '60vh' : size)};
  justify-self: center;

  margin-bottom: 3vh;

  .pulse {
    border-radius: 50%;
    background-color: ${({ status }) => `${CircleColor[status]}`};
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    position: absolute;
    opacity: 0;
    animation: scaleIn 3s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
  }

  @media (max-width: 1200px) {
    --max-size: '40vh';
    width: ${({ size = '200px' }) => size};
    height: ${({ size = '200px' }) => size};
    border-radius: ${({ size = '200px' }) => size};
  }

  @media (max-width: 768px) {
    width: 40vw;
    height: 40vw;
    margin-bottom: 3vh;
  }

  @media (max-width: 480px) {
    width: 60vw;
    height: 60vw;
    margin-bottom: 0;
  }
`;

export const Circle = styled(Column)`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  color: ${({ offline }) => (offline ? '#ACAFB3' : '#fff')};
  font-size: 24px;

  position: relative;
  z-index: 5;
  background: ${({ status }) => `linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%) ${CircleColor[status]}`};
  box-shadow:
    0 10px 10px rgba(34, 34, 34, 0.35),
    inset 0 2px 1px rgba(255, 255, 255, 0.1);
  background-blend-mode: overlay, normal;

  & svg {
    width: 50%;
    height: auto;
    fill: ${({ offline }) => (offline ? '#ACAFB3' : '#fff')};
  }
  @keyframes scaleIn {
    from {
      transform: scale(0.5, 0.5);
      opacity: 0.4;
    }
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
`;

export const Label = styled(Text).attrs({
  as: 'p'
})`
  font-size: 0.8em;

  @media (max-width: 768px) {
    font-size: 0.6em;
  }
`;

export const Value = styled(Text).attrs({
  as: 'h1'
})`
  font-size: 1.35em;
  text-transform: uppercase;
  margin-bottom: 0.25em;

  @media (max-width: 768px) {
    font-size: 1em;
  }

  @media (max-height: 640px) {
    font-size: 1em;
  }
`;
