import { combineReducers } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

export const startRequest = createAction('@@app/START_REQUEST');
export const startSuccess = createAction('@@app/START_SUCCESS');
export const startFailure = createAction('@@app/START_FAILURE');

export const selectParameter = createAction('@@app/SELECT_PARAMETER');

export const startRotatingParameters = createAction('@@app/START_ROTATING_PARAMETERS');
export const stopRotatingParameters = createAction('@@app/STOP_ROTATING_PARAMETERS');

export const checkNetworkStatus = createAction('@@app/CHECK_NETWORK_STATUS');
export const setNetworkStatus = createAction('@@app/SET_NETWORK_STATUS');

export const checkApiStatus = createAction('@@app/CHECK_API_STATUS');
export const retryCheckApiStatus = createAction('@@app/RETRY_CHECK_API_STATUS');
export const setApiStatus = createAction('@@app/SET_API_STATUS');

export const checkProfileName = createAction('@@app/CHECK_PROFILE_NAME');

const currentParameter = createReducer(null, (builder) => builder.addCase(selectParameter, (state, { payload }) => payload));
const isRotating = createReducer(true, (builder) =>
  builder.addCase(stopRotatingParameters, () => false).addCase(startRotatingParameters, () => true)
);
const isNetworkOnline = createReducer(true, (builder) => builder.addCase(setNetworkStatus, (state, { payload }) => payload));
const isApiOnline = createReducer(true, (builder) => builder.addCase(setApiStatus, (state, { payload }) => payload));

const app = combineReducers({
  isRotating,
  currentParameter,
  isNetworkOnline,
  isApiOnline
});

export const getAppIsRotating = (state) => state.app.isRotating;
export const getAppCurrentParameter = (state) => state.app.currentParameter;
export const getAppIsNetworkOnline = (state) => state.app.isNetworkOnline;
export const getAppIsApiOnline = (state) => state.app.isApiOnline;

export default app;
