/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import { withTheme } from 'styled-components';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import chartOptions from './chartOptions';
import { Container, Wrapper } from './styles';

const SimpleChart = React.memo(
  (props) => {
    const { data, offline, timezone, theme, round, title } = props;

    const { simpleChartLineColor, simpleChartAxisLines, simpleChartOffline } = theme.colors;

    const lineColor = offline ? simpleChartOffline : simpleChartLineColor;
    const gridColor = offline ? simpleChartOffline : simpleChartAxisLines;

    const updatedChartOptions = merge(
      { ...chartOptions },
      {
        time: {
          timezone
        },
        series: [
          {
            data,
            name: title,
            round
          }
        ],
        colors: [lineColor],
        xAxis: {
          gridLineColor: gridColor,
          labels: {
            style: {
              color: gridColor
            }
          }
        }
      }
    );
    return (
      <Container>
        <Wrapper>
          <HighchartsReact
            highcharts={Highcharts}
            options={updatedChartOptions}
            containerProps={{ style: { height: '100%' } }}
            constructorType="stockChart"
          />
        </Wrapper>
      </Container>
    );
  },
  (prevProps, nextProps) => {
    const { offline, data, path } = nextProps;
    const { offline: offlinePrev, data: dataPrev, path: pathPrev } = prevProps;
    if (offline !== offlinePrev || data.length !== dataPrev.length || path !== pathPrev) {
      return false;
    }
    return true;
  }
);

SimpleChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  offline: PropTypes.bool.isRequired
};

export default withTheme(SimpleChart);
