/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';
import { fetchDataPointsSuccess, fetchDataPointsFailure, fetchDataPointsRequest } from '~/store/reducers/dataPoints';

export const fetchNodesRequest = createAction('@@nodes/FETCH_REQUEST');
export const fetchNodesSuccess = createAction('@@nodes/FETCH_SUCCESS');
export const fetchNodesFailure = createAction('@@nodes/FETCH_FAILURE');

const idsInitialState = [];
const ids = createReducer(idsInitialState, (builder) => builder.addCase(fetchNodesSuccess, (state, { payload: { result } }) => result));

const byIdData = {};
const byId = createReducer(byIdData, (builder) =>
  builder
    .addCase(fetchNodesSuccess, (state, { payload }) => {
      if (payload.entities.nodes) {
        return payload.entities.nodes;
      }
      return state;
    })
    .addCase(fetchDataPointsSuccess, (state, { payload }) => {
      const { result } = payload;
      if (result) {
        const [nodeId] = Object.keys(state);
        state[nodeId].data_points = result;
      }
    })
);

const isFetched = createReducer(false, (builder) => builder.addCase(fetchDataPointsSuccess, () => true));
const isFetching = createReducer(false, (builder) =>
  builder
    .addCase(fetchDataPointsRequest, () => true)
    .addCase(fetchDataPointsFailure, () => false)
    .addCase(fetchDataPointsSuccess, () => false)
);

const isFetchFailure = createReducer(false, (builder) => builder.addCase(fetchNodesFailure, () => true).addCase(fetchDataPointsFailure, () => true));

const nodes = combineReducers({
  ids,
  byId,
  isFetched,
  isFetching,
  isFetchFailure
});

export const getNode = (state, id) => state.nodes.byId[id];
export const getIds = (state) => state.nodes.ids;
export const getIsFetched = (state) => state.nodes.isFetched;
export const getIsFetching = (state) => state.nodes.isFetching;
export const getIsFetchFailure = (state) => state.nodes.isFetchFailure;

export default nodes;
