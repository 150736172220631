import styled from 'styled-components';
import { Flex, Text, Small } from '~/components/Basic';

export const Container = styled(Flex)`
  margin: 0 auto;
  max-width: 600px;
  background: linear-gradient(180deg, #4399d8 0%, #1d6eb8 100%);
  background: ${({ offline }) =>
    offline
      ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%) #5f6a75'
      : 'linear-gradient(180deg, #4399d8 0%, #1d6eb8 100%)'};
  box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  padding: 1vw 2vw 1vw 1vw;
  box-sizing: border-box;
  flex-direction: column;

  color: ${({ offline }) => (offline ? '#ACAFB3 !important' : '#fff')};

  & svg {
    fill: ${({ offline }) => (offline ? '#ACAFB3 !important' : '#fff')};
  }

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const TopPart = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  --size: 5vw;

  svg {
    fill: #fff;
    width: var(--size);
    height: var(--size);
  }
  font-size: 24px;

  @media (max-width: 1024px) {
    --size: 3em;
  }

  @media (max-width: 480px) {
    --size: 20%;
  }
`;

export const Label = styled(Text)`
  font-weight: normal;
`;

export const Value = styled(Label)`
  font-size: 1em;
  font-weight: bold;
`;

export const Unit = styled(Small)`
  font-size: 0.75em;
  font-weight: normal;
  padding-left: 0.3vw;
`;

export const LabelBlock = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  svg {
    width: 60px;
    height: 60px;
  }

  p {
    font-size: 18px;
  }
`;
