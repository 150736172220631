import * as Sentry from '@sentry/react';
import { getReleaseName } from '~/utils/helpers';

const { VITE_SENTRY_DSN, CI } = process.env;

(() => {
  if (CI) {
    Sentry.init({
      ignoreErrors: ['Non-Error exception captured'],
      dsn: VITE_SENTRY_DSN,
      release: getReleaseName()
    });
  }
})();
