import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import * as fromDataPoints from '~/store/reducers/dataPoints';
import * as fromNodes from '~/store/reducers/nodes';

export const getNodesIsFetched = (state) => fromNodes.getIsFetched(state);
export const getNodesIsFetching = (state) => fromNodes.getIsFetching(state);

export const getIsFetchFailure = (state) => fromNodes.getIsFetchFailure(state);

export const getPath = (dataPoint = {}) => {
  const { path = '' } = dataPoint;
  const [, device, pathId] = path.split(':');
  return `${device}:${pathId}`;
};

const isDefault = (dataPoint) => {
  const path = getPath(dataPoint);
  const hasPath = !!dataPoint.path;
  // eslint-disable-next-line no-underscore-dangle
  const isCorrectType = !includes(['info', 'status', 'location', 'debug', 'error', 'raw', 'config'], dataPoint._type);
  return hasPath && isCorrectType && !includes(['0:23', '0:24', '0:25'], path);
};

export const getDefaultDataPointsIds = createSelector(fromDataPoints.getDataPoints, (dataPoints) =>
  Object.values(dataPoints)
    .filter(isDefault)
    .map(({ _id: id }) => id)
);

export function getDataPointById(state, id) {
  return fromDataPoints.getDataPoint(state, id);
}

export const getDataPoints = createSelector(fromDataPoints.getDataPoints, (dataPoints) => Object.values(dataPoints));

export const getDataPointsByTypeExternal = createSelector(getDataPoints, (dataPoints) => {
  const externals = dataPoints.filter(({ _type }) => _type === 'external');
  return sortBy(externals, 'name');
});

export const getDataPointByPath = createSelector(
  getDataPoints,
  (state, path) => path,
  (dataPoints, path) => dataPoints.find((point) => point.path && includes(point.path, path)) || {}
);
