import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Default from '~/pages/Default';

export default function () {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Default />} />
      </Route>
    </Routes>
  );
}
