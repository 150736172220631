import { schema } from 'normalizr';

export const measurementsSchema = new schema.Entity(
  'measurements',
  {},
  {
    idAttribute: 'data_point_id'
  }
);

export const dataPointSchema = new schema.Entity(
  'data_points',
  {
    measurements: measurementsSchema
  },
  {
    idAttribute: '_id'
  }
);
export const dataPointsSchema = [dataPointSchema];

export const deviceSchema = new schema.Entity(
  'devices',
  {},
  {
    idAttribute: '_id'
  }
);
export const devicesSchema = [deviceSchema];

export const nodesSchema = new schema.Entity(
  'nodes',
  {
    data_points: dataPointsSchema,
    devices: devicesSchema
  },
  { idAttribute: '_id' }
);

export const nodesListSchema = [nodesSchema];
