import React from 'react';
import ReactDOM from 'react-dom/client';
import '~/config/sentry';
import '~/config/datadog';
import '~/config/i18n';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);
