import { put, take, call, select, cancelled, all, fork, takeEvery, getContext } from 'redux-saga/effects';
import isNil from 'lodash/isNil';
import { createInterval } from '~/store/sagas/utils';
import requestApi from '~/utils/axios';
import { currentAppVersion, getCurrentAppVersion, checkNewRelease } from '~/store/reducers/appVersion';

const CHECK_NEW_RELEASE_INTERVAL = 300000;

export function createIntervalChecking() {
  return createInterval(CHECK_NEW_RELEASE_INTERVAL);
}

export function* checkNewReleaseSaga() {
  const history = yield getContext('history');
  const origin = `${history.location.pathname}${history.location.search}`;
  const {
    headers: { 'last-modified': lastModified }
  } = yield call(requestApi, 'HEAD', origin, null, { api: false });
  if (isNil(lastModified)) {
    return;
  }
  yield put(currentAppVersion(lastModified));
  const chan = yield call(createIntervalChecking);
  try {
    while (true) {
      yield take(chan);
      const currentLocalVersion = yield select(getCurrentAppVersion);
      const {
        headers: { 'last-modified': currentRemoteVersion }
      } = yield call(requestApi, 'HEAD', origin, null, { api: false });
      if (currentLocalVersion !== currentRemoteVersion) {
        history.go(0);
      }
    }
  } finally {
    if (yield cancelled()) {
      chan.close();
    }
  }
}

export function* watchCheckNewRelease() {
  yield takeEvery(checkNewRelease, checkNewReleaseSaga);
}

export default function* appVersion() {
  yield all([fork(watchCheckNewRelease)]);
}
