import { call, put, fork, takeEvery, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { normalizeNodesResp } from '~/utils/helpers';
import { fetchNodesRequest, fetchNodesSuccess, fetchNodesFailure } from '~/store/reducers/nodes';
import * as schema from '~/store/schema';
import requestApi from '~/utils/axios';
import { getAuthIdToken } from '~/store/selectors/auth';

export function* nodesFetchRequestSaga() {
  try {
    const token = yield select(getAuthIdToken);
    const { data } = yield call(
      requestApi,
      'post',
      'search/nodes',
      {
        include_configuration: true,
        includes: 'profiles',
        type: 'thiamis',
        tags: 'airthinx'
      },
      { token }
    );
    const response = normalize(normalizeNodesResp(data), schema.nodesListSchema);
    yield put(fetchNodesSuccess(response));
  } catch (e) {
    yield put(fetchNodesFailure(e));
  }
}

function* watchLoadNodes() {
  yield takeEvery(fetchNodesRequest, nodesFetchRequestSaga);
}

export default function* app() {
  yield fork(watchLoadNodes);
}
