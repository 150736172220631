import React from 'react';
import { RANGES } from '~/utils/constants';
import { Container, Label } from './styles';
import Legend from './Legend';
import FillChart from './FillChart';

function ExposureTime(props) {
  const { qualityNumbers } = props;

  const mappedValues = qualityNumbers.map((item) => ({
    color: RANGES[item.label].color,
    label: item.label,
    value: item.value
  }));

  const slices = qualityNumbers.map((item) => ({
    color: RANGES[item.label].color,
    size: item.value / 100
  }));

  return (
    <Container>
      <Label>Exposure Time</Label>
      <FillChart slices={slices} height="8px" />
      <Legend values={mappedValues} />
    </Container>
  );
}

export default ExposureTime;
