import { datadogRum } from '@datadog/browser-rum';
import { getReleaseName, getEnv } from '~/utils/helpers';

const { CI, VITE_DATADOG_CLIENT_TOKEN, VITE_DATADOG_APPLICATION_ID } = process.env;

(() => {
  if (CI) {
    datadogRum.init({
      applicationId: VITE_DATADOG_APPLICATION_ID,
      clientToken: VITE_DATADOG_CLIENT_TOKEN,
      version: getReleaseName(),
      service: `dashboard.${getEnv()}.io`,
      trackUserInteractions: false,
      trackResources: true,
      trackLongTasks: true,
      trackInteractions: false,
      defaultPrivacyLevel: 'allow'
    });
  }
})();
