import styled from 'styled-components';
import { Column } from '~/components/Basic';

export const WidgetContainer = styled(Column).attrs({
  p: 'DOUBLE',
  borderColor: 'widgetBorder',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: 'BIG',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'widgetBackground',
  boxShadow: 'widgetShadow'
})`
  display: inline-flex;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  grid-column: ${({ span = 1 }) => `auto / span ${span}`};
  @media screen and (max-width: 768px) {
    grid-column: ${({ mobileSpan = 1 }) => `auto / span ${mobileSpan}`};
  }
  ${({ selected }) => selected && 'outline:none;border-color:#9ecaed;box-shadow:0 0 10px #9ecaed;'}
`;
