/* eslint-disable import/no-unresolved */
import { AIRTHINX_DEFAULT_PARAMS } from '~/utils/constants';
import IconAirQuality from '~/assets/icons/icon-air-quality.svg?react';
import IconCarbonDioxide from '~/assets/icons/icon-carbon-dioxide.svg?react';
import IconFormaldehyde from '~/assets/icons/icon-formaldehyde.svg?react';
import IconHumidity from '~/assets/icons/icon-humidity.svg?react';
import IconPressure from '~/assets/icons/icon-pressure.svg?react';
import IconTemperature from '~/assets/icons/icon-temperature.svg?react';
import IconVOC from '~/assets/icons/icon-volatile-compounds.svg?react';
import IconPM from '~/assets/icons/icon-particulare-matter.svg?react';

const icons = {
  airQuality: IconAirQuality,
  carbonDioxide: IconCarbonDioxide,
  formaldehyde: IconFormaldehyde,
  humidity: IconHumidity,
  pressure: IconPressure,
  temperature: IconTemperature,
  pm: IconPM,
  voc: IconVOC
};

export default function getIconByPath(path) {
  return icons[AIRTHINX_DEFAULT_PARAMS[path]?.icon];
}
