import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ChartBlock from '~/components/Brand/ChartBlock';
import { getAppCurrentParameter } from '~/store/selectors/app';
import { getDataPointByPath } from '~/store/selectors/dataPoints';
import { getNodeDataAggregation } from '~/store/selectors/nodes';
import {
  getNodeIsOffline,
  getLastMeasurementTs,
  getMeasurementsValue,
  getMeasurementsLastValue,
  getMeasurementsLastValueAggregated
} from '~/store/selectors/measurements';
import { PARAMETER_PATHS } from '~/utils/constants';

const mapStateToProps = (state) => {
  const selectedDataPointPath = getAppCurrentParameter(state);

  const { _id: dataPointId, name, unit, round, node_id: nodeId } = getDataPointByPath(state, selectedDataPointPath);
  const data = getMeasurementsValue(state, dataPointId);
  const lastTs = getLastMeasurementTs(state);
  const isOffline = getNodeIsOffline(lastTs);
  const aggregation = getNodeDataAggregation(state, nodeId);
  const { _id: tzDataPointId } = getDataPointByPath(state, PARAMETER_PATHS.TIMEZONE);
  const timezone = getMeasurementsLastValue(state, tzDataPointId);

  return {
    name,
    unit,
    round,
    isOffline,
    timezone,
    data,
    dataPointPath: selectedDataPointPath,
    value: getMeasurementsLastValueAggregated(state, dataPointId, aggregation)
  };
};

export default withTranslation('parameters')(connect(mapStateToProps)(ChartBlock));
