import { combineReducers } from 'redux';
import { createReducer, createAction } from '@reduxjs/toolkit';

export const setIdToken = createAction('@@auth/SET_ID_TOKEN');

const idToken = createReducer(null, (builder) => builder.addCase(setIdToken, (state, { payload }) => payload));

const auth = combineReducers({
  idToken
});

export const getAuthIdToken = (state) => state.auth.idToken;

export default auth;
