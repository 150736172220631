import styled from 'styled-components';
import { Grid, Text } from '~/components/Basic';
import { CircleColor } from '~/components/Brand/HeroCircle/styles';
import { AQI_LABELS, RANGES } from '~/utils/constants';

export const Container = styled(Grid)`
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  align-items: center;
  color: ${({ active, offline }) => {
    if (offline) {
      return RANGES.offline.color;
    }

    return active ? '#fff' : '#6298DA';
  }};
  cursor: pointer;

  & svg {
    width: 80px;
    height: auto;
    fill: ${({ active, offline }) => {
      if (offline) {
        return RANGES.offline.color;
      }

      return active ? '#fff' : '#6298DA';
    }};
  }

  @media (max-width: 1200px) {
    color: ${({ offline }) => (offline ? RANGES.offline.color : '#fff')};

    & svg {
      fill: ${({ offline }) => (offline ? RANGES.offline.color : '#fff')};
    }
  }

  @media (max-width: 768px) {
    display: block;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
    & svg {
      width: 60px;
    }
  }

  @media (max-width: 600px) {
    cursor: auto;
  }
`;

export const TextContainer = styled(Grid)`
  grid-template-columns: 100%;
  grid-gap: 5px;
  font-size: 20px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const Label = styled(Text)`
  font-size: 1em;
  text-overflow: ellipsis;
  overflow: hidden;
  span {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    margin-right: 6px;
    background-color: #6f9fd6;
  }

  @media (max-width: 390px) {
    font-size: 18px;
  }
`;

export const Value = styled(Text)`
  font-size: 1.35em;
  font-weight: bold;
  & span {
    margin-left: 5px;
    font-weight: normal;
  }

  @media (max-width: 768px) {
    font-size: 1em;
  }

  @media (max-width: 390px) {
    font-size: 16px;
  }
`;

export const IconBlock = styled.div`
  position: relative;
  &:after {
    content: '';
    display: block;
    --size: 12px;
    width: var(--size);
    height: var(--size);
    border-radius: var(--size);
    top: 0;
    left: 0;
    position: absolute;
    background-color: ${({ status, offline }) => {
      if (offline) {
        return 'transparent';
      }

      return status === AQI_LABELS.MODERATE || status === AQI_LABELS.POOR ? CircleColor[status] : 'transparent';
    }};
  }

  @media (max-width: 480px) {
    svg {
      width: 16vw;
    }
  }

  &:after {
    --size: 10px;
  }
`;
