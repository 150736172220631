import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import airthinxTheme from '~/themes/airthinx';
import { Waves, Divider, FullPage, RightPart, CentralPart, TwoWaySplit, ParametersBlock } from '~/components/Brand/Common/styles';
import Hero from '~/components/Brand/HeroCircle';
import ExposureTime from '~/containers/ExposureTime';
import { LogoLight } from '~/components/Brand/Logo';
import Location from '~/components/Brand/Location';
import ParametersGrid from '~/components/Brand/Parameters';
import { getNodesIdFirst, getNodeShowDashboardExposureTime, getNodeDefaultParameters, getNodeByIdName } from '~/store/selectors/nodes';
import { startRotatingParameters } from '~/store/reducers/app';
import { getNodeIsOffline, getLastMeasurementTs } from '~/store/selectors/measurements';
import withAppStart from '~/components/hoc/withAppStart';
import ChartBlock from '~/containers/ChartBlock';
import TimeAgo from '~/components/Brand/TimeAgo';

function Layout({ offline, showExposureTime, params }) {
  return (
    <FullPage offline={offline} name="horizontal">
      <LogoLight />
      <Location />
      <Waves />
      <TwoWaySplit>
        <CentralPart>
          <Hero size="400px" iconSize="180px" label="Air Quality" />
          {showExposureTime && <ExposureTime />}
          {showExposureTime && <Divider />}
        </CentralPart>
        <RightPart offline={offline}>
          <ChartBlock />
          <ParametersBlock>
            <ParametersGrid params={params} layout="columns-2" />
          </ParametersBlock>
          <TimeAgo />
        </RightPart>
      </TwoWaySplit>
    </FullPage>
  );
}

function Default(props) {
  const { isOffline, showExposureTime, params, lastUpdate, aggregation, title } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startRotatingParameters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={airthinxTheme}>
      {title ? (
        <Helmet>
          <title>{`Aethair Dashboard | ${title}`}</title>
        </Helmet>
      ) : undefined}

      <Layout params={params} offline={isOffline} lastUpdate={lastUpdate} aggregation={aggregation} showExposureTime={showExposureTime} />
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  const nodeId = getNodesIdFirst(state);
  const showExposureTime = getNodeShowDashboardExposureTime(state, nodeId);
  const lastTs = getLastMeasurementTs(state);
  const isOffline = getNodeIsOffline(lastTs);
  return {
    params: getNodeDefaultParameters(state, nodeId),
    title: getNodeByIdName(state, nodeId),
    isOffline,
    showExposureTime
  };
};

export default connect(mapStateToProps)(withAppStart(Default));
